/* 공고 상태 리스트 */
const PROCESS_STATUS = {
  OPENED: "opened",
  PENDING: "pending",
  ALL: "all",
  CLOSED: "closed"
}

/* Tab 리스트 */
const TAB_LIST = [
  {
    id: 1,
    key: PROCESS_STATUS.OPENED,
    name: "진행 중",
    count: 0
  }, {
    id: 2,
    key: PROCESS_STATUS.PENDING,
    name: "대기",
    count: 0
  }, {
    id: 3,
    key: PROCESS_STATUS.ALL,
    name: "전체",
    count: 0
  }, {
    id: 4,
    key: PROCESS_STATUS.CLOSED,
    name: "마감",
    count: 0
  }
];

const STATUS_LIST = {
  UPDATE_PENDING: "update_pending",
  UPDATE_REJECTED: "update_rejected",
  REVIEW_PENDING: "review_pending",
  REVIEW_REJECTED: "review_rejected",
  PRIVATE: "private",
  OPENED_NOT_YET: "opened_not_yet",
  OPEN_ENDED: "open_ended",
  END_AT_HIRED: "end_at_hired",
}

/* 공고 상태 상세 리스트 */
const POSTING_STATUS = {
  [STATUS_LIST.UPDATE_PENDING]: {
    name: "수정 요청",
    color: "green",
  },
  [STATUS_LIST.UPDATE_REJECTED]: {
    name: "수정 요청 거절",
    color: "red",
  },
  [STATUS_LIST.REVIEW_PENDING]: {
    name: "등록대기",
    color: "blue",
  },
  [STATUS_LIST.REVIEW_REJECTED]: {
    name: "등록거절",
    color: "red",
  },
  [STATUS_LIST.PRIVATE]: {
    name: "비공개",
    color: "",
  },
  [STATUS_LIST.OPENED_NOT_YET]: {
    name: "노출대기",
    color: "blue",
  },
  [STATUS_LIST.OPEN_ENDED]: {
    name: "상시채용",
    color: "gray",
  },
  [STATUS_LIST.END_AT_HIRED]: {
    name: "채용시 마감",
    color: "gray",
  },
}

const SORT_LIST = [
  {
    key: "start_at",
    name: "접수 시작일 순",
  }, {
    key: "created_at",
    name: "최신 등록 순",
  }, {
    key: "end_at",
    name: "마감일 순",
  },
];

const APPLICATION_STATUS = [
  {
    key: "total",
    name: "지원자",
    color: ""
  }, {
    key: "not_opened",
    name: "미열람",
    color: "green"
  }, {
    key: "resume_passed",
    name: "서류 합격",
    color: ""
  }, {
    key: "interview_passed",
    name: "최종 합격",
    color: ""
  }
];

export { PROCESS_STATUS, TAB_LIST, STATUS_LIST, POSTING_STATUS, SORT_LIST, APPLICATION_STATUS };