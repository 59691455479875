import React, { Component, Suspense } from "react";
import {Redirect, Switch} from "react-router-dom";
import { PublicRoute } from "react-router-with-props";
import { Provider } from "react-redux";
import "../assets/scss/partners.scss";
import ReactGA from "react-ga";
import { QueryClient, QueryClientProvider } from "react-query";
import Reducer from "./reducer";
import { rootSaga } from "./sagas";
import JplySpinner from "../commons/JplySpinner";
import initialState from "./initialState";
import {
  PartnersHome,
  SalaryManagement,
  WelfareManagement,
  JobPostingManagement,
  ApplicantManagement,
  OperatorManagement,
  NoAccessManagement
} from "./pages";
import ModalContainer from "./components/ModalContainer";
import PageContainer from "./components/PageContainer";
import JobLnbMenu from "../atOrganisms/JobLnbMenu";
import Job from "./job/Job";
import { gtmVirtualPageView, reduxJpBase } from "../Helper/gm";

const test_base_url = process.env.REACT_APP_BACKEND_HOST;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

class Partners extends Component {
  constructor(props) {
    super(props);
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID, {
      debug: false,
    });
    this.store = reduxJpBase({
      set_reducer: Reducer,
      base_reducer: {...initialState},
      root_saga: rootSaga
    });
  }

  componentDidMount() {
    const { location } = this.props;
    /**
     * 23.3 박승호 GTM페이지 이벤트
     * */
    const page_name = process.env.REACT_APP_SPA_HOST + location.pathname;
    const mainDataLayer = {
      pageTypeName: page_name || null,
      virtualUrl: location.pathname || null,
    };
    gtmVirtualPageView(mainDataLayer);
    /*if (isEmpty(this.props.alri.business_status)) {
      this.initCall().map(d => {
        return this.props.ActionCall(d);
      });
    }*/
  }

  render() {
    const { location: { pathname } } = this.props;
    return (
      <Provider store={this.store}>
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<JplySpinner />}>
            <PageContainer {...this.props}>
              {(pathname.includes("/job_management") || pathname.includes("/applicant_management")) && (
                <JobLnbMenu/>
              )}
              <Switch>
                <PublicRoute
                  exact
                  path="/partners/reputation_management"
                  component={PartnersHome}
                  base_url={test_base_url}
                />
                <PublicRoute
                  exact
                  path="/partners/salary_managements"
                  component={SalaryManagement}
                  base_url={test_base_url}
                />
                <PublicRoute
                  exact
                  path="/partners/welfare_managements"
                  component={WelfareManagement}
                  base_url={test_base_url}
                />
                <PublicRoute
                  exact
                  path="/partners/job_management"
                  component={JobPostingManagement}
                  base_url={test_base_url}
                />
                <PublicRoute
                  exact
                  path="/partners/job_management/:id"
                  component={JobPostingManagement}
                  base_url={test_base_url}
                />
                <PublicRoute
                  exact
                  path="/partners/applicant_management"
                  component={ApplicantManagement}
                  base_url={test_base_url}
                />
                <PublicRoute
                  exact
                  path="/partners/applicant_management/:id"
                  component={ApplicantManagement}
                  base_url={test_base_url}
                />
                <PublicRoute
                  exact
                  path="/partners/operator_managements"
                  component={OperatorManagement}
                  base_url={test_base_url}
                />
                <PublicRoute
                  exact
                  path="/partners/access_error"
                  component={NoAccessManagement}
                  base_url={test_base_url}
                />
	
                {/* Job Route 추가 */}
                <Job
                  base_url={test_base_url}
                />
								
                <PublicRoute exact path="/partners/" component={PartnersHome} base_url={test_base_url} />
                <Redirect to="/error"/>
              </Switch>
            </PageContainer>
          </Suspense>
          <ModalContainer />
        </QueryClientProvider>
      </Provider>
    );
  }
}

export default Partners;
