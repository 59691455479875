import axios from "axios";
import { isEmpty } from "./gm";

const api = axios.create({
  timeout: 60000,
  withCredentials: true,
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
  const accessErrorUrl = "/partners/access_error"
    if (error.response
      && error.response.status === 403
      && !window.location.href.includes(accessErrorUrl)
      && (localStorage.getItem("jobplanet_login") !== "login_check_error")
  ) {
    window.location.href = accessErrorUrl;
    }

    if (error.response && error.response.status === 302) {
      const redirectLocation = error.response.headers.location;
      if (!window.location.href.includes(redirectLocation)){
        window.location.href = redirectLocation; 
      }
    } 
    return Promise.reject(error);
  }
);

export default function fetchDog(option) {
  const { test_base_url, url, params, headers, responseType } = option;

  return api({
    baseURL: isEmpty(test_base_url) ? process.env.REACT_APP_BACKEND_HOST : test_base_url,
    method: "get",
    url,
    params,
    responseType: responseType ? responseType : "json",
    headers: Object.assign({
      Accept: 'application/json'
    }, headers),
    withCredentials: true,
  });
}