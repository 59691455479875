import React from "react";
import { PublicRoute } from "react-router-with-props";
import {
  RegistrationManagement,
  RegistrationEditManagement
} from "./page";

const Job = (props) => {
  const { base_url } = props;
  return (
    <>
      <PublicRoute
        exact
        path="/partners/job/postings/new"
        component={RegistrationManagement}
        base_url={base_url}
      />
      <PublicRoute
        exact
        path="/partners/job/postings/:id/edit"
        component={RegistrationEditManagement}
        base_url={base_url}
      />
    </>
  );
};

export default Job;
