import axiosGet from "../../Helper/axiosGet";
import { useQuery } from "react-query";

const fetchModal = async () => {
  const { data } = await axiosGet({
    url: "/api/v3/business_center/relay_signup/popup",
  });
  return data;
};

const useQueryModal = (option = {}) =>
  useQuery("partnersModal", fetchModal, {
    retry: 0,
  });

export default useQueryModal;
