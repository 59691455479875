import React, {useEffect} from  'react';
import {useQueryClient} from "react-query";
import JplyCheckBox from "../../JplyTemp/components/JplyCheckBox";
import getGAevent from "../lib/getGAevent";

const ModalWelcomeTasks = ({deleteFirstOrder, modalClose}) => {
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData("utilizationRate");
  const base_url = process.env.REACT_APP_BACKEND_HOST;
  
  const contents = [
    {
      title: "기업 정보 입력하기",
      description: "기업로고, 대표자, 기업형태, 사원수, 회사소개를 입력해 주세요.",
      icon_url: "https://jpassets.jobplanet.co.kr/production/uploads/material/media/5627/icon_emoji_write.png",
      url: `${base_url}/partners/profile/edit`,
      key_code: "profile",
    },
    {
      title: "기업 커버 이미지 추가하기",
      description: "가장 중요한 기업의 첫 인상! 커버 이미지를 등록해 주세요.",
      icon_url: "https://jpassets.jobplanet.co.kr/production/uploads/material/media/5624/icon_emoji_wink.png",
      url: `${base_url}/partners/enhanced_profile/cover_photo`,
      key_code: "cover_photo",
    },
    {
      title: "리뷰에 댓글 등록하기",
      description: "직원과 소통하고 있음을 보여주세요. 좋은 인상을 남길 수 있어요.",
      icon_url: "https://jpassets.jobplanet.co.kr/production/uploads/material/media/5623/icon_emoji_pin.png",
      url: `${base_url}/partners/review_managements`,
      key_code: "review_comments",
    },
    {
      title: "면접 후기에 댓글 등록하기",
      description: "인재 관리의 꼼꼼함을 보여주세요. 좋은 인상을 남길 수 있어요.",
      icon_url: "https://jpassets.jobplanet.co.kr/production/uploads/material/media/5626/icon_emoji_interview.png",
      url: `${base_url}/partners/interview_managements`,
      key_code: "interview_comments",
    },
    {
      title: "채용 공고 등록하기",
      description: "구직자가 쉽게 찾아볼 수 있도록 채용 공고를 등록하세요.",
      icon_url: "https://jpassets.jobplanet.co.kr/production/uploads/material/media/5622/icon_emoji_folder.png",
      url: `${base_url}/partners/job/postings`,
      key_code: "job_postings",
    },
  ];
  
  useEffect(() => {
    if(data && isDoneAll(data.data.items)){
      closeModalHandler();
    }
  },[data]);
  
  
  const gaEventHandler = ({label}) => {
    getGAevent({
      action: "Popup_기다리고 있었어요",
      label
    });
  };
  
  const getNewContents = items => {
    return contents.map(v => {
      v.in_use = items.find(item => v.key_code === item.key_code).in_use;
      return v;
    })
  };
  
  const isDoneAll = items => items && getNewContents(items).every(({in_use}) => (in_use === true));
  
  const onChecked = (key, value) => {
    if(value){
      localStorage.setItem("PartnersWelcomeModalCloseDate", new Date());
    } else {
      localStorage.removeItem("PartnersWelcomeModalCloseDate");
    }
  };
  
  const closeModalHandler = () => {
    modalClose();
    deleteFirstOrder();
  };
  
  const renderTasks = (data) => {
    
    return (
      <ul className="task_list">
        {
          getNewContents(data).map(({title, description, icon_url, url, in_use}, i) =>
            <li className={`task_item ${in_use ? "done" : ""}`} key={i}>
              <div className="text_box">
                <div className="tit"><i className="task_icon"><img src={icon_url} alt={title} /></i><em className="tit_text">{title}</em></div>
                <p className="desc">{description}</p>
              </div>
              {
                in_use ? (
                  <div className="done_text">
                    <i className="jply_icon jp-check"></i><span>잘하셨어요!</span>
                  </div>
                ) : (
                  <a
                    href={url}
                    className="task_link"
                    onClick={() => {
                      gaEventHandler({
                        label: title
                      })
                    }}>
                    <span>시작하기</span><i className="jply_icon jp-chevron-right"></i>
                  </a>
                )
              }
            </li>
          )
        }
      </ul>
    );
  };
  
  if(!data || (data && isDoneAll(data.data.items))) return null;
  
  const { items } = data.data;
  
  return (
    <div className="jply_popup layer_popup_box layer_popup_box_on">
      <div className="layer_popup_bg"></div>
      <div className={`layer_popup jply_modal_contents_ty modal_welcome_tasks`} tabIndex={0}>
        <div className={`jply_modal_v2`}>
          <div className="modal_contents_wrap">
            <div className="modal_header">
              <div className="title">기다리고 있었어요, 기업회원이 되신 것을 진심으로 환영해요.</div>
            </div>
            <div className="modal_body">
              <div className="modal_sub_text">
                뭐부터 할지 모르겠다면? 아래 순서대로 차근차근 미션 클리어!
              </div>
              <div className="welcome_task_box">
                {
                  renderTasks(items)
                }
              </div>
              <div className="button_group">
                <div className="check_box_wrap">
                  <JplyCheckBox
                    value={false}
                    name={"이제 그만 볼게요"}
                    onChecked={onChecked}
                    keyName={"close"}
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn_close_x_ty1"
            onClick={closeModalHandler}>
            <span className="jply_icon jp-x" ></span>
            <span className="sr-only">닫기</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalWelcomeTasks;
