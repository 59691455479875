import {useSelector, useDispatch} from "react-redux";

/* Action creators */
const actionOpenModal = (payload = {}) => {
  return {
    type: "SET_MODAL",
    payload: {
      show: true,
      ...payload,
    },
  };
};

const actionCloseModal = (payload = {}) => ({
  type: "SET_MODAL",
  payload: {
    show: false,
    ...payload,
  },
});

const useModal = () => {
  const dispatch = useDispatch();
  const modal = useSelector(state => state.modal);
  
  const onClickModalOpen = (payload) => {
    dispatch(actionOpenModal(payload));
  };
  
  const onClickModalClose = (payload) => (
    dispatch(actionCloseModal(payload))
  );
  
  return { modal, onClickModalOpen, onClickModalClose };
};

export default useModal;
