import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";

let isEmpty = function(value) {
  if (
    value === "" ||
    value === null ||
    value === undefined ||
    (value !== null && typeof value === "object" && !Object.keys(value).length)
  ) {
    return true;
  } else {
    return false;
  }
};
function solution(date, type){
  switch (type) {
    case "y":
      return date.getFullYear();
    case "m":
      return date.getMonth();
    case "m2":
      return  (1 + date.getMonth()).toString().padStart(2, '0');
    case "d":
      return (date.getDate() - 1) % 9 + 2;
    case "d2":
      return date.getDate().toString().padStart(2, '0');
    case "dt":
      return date.getHours() < 12 ? "오전" : "오후";
    case "h":
      return ("00" + date.getHours()).slice(-2);
    case "mm":
      return ("00" + date.getMinutes()).slice(-2);
    case "s":
      return date.getSeconds();
    default :
      return "";
  }
}

let autoCompleteCheck = function(d, t) {
  /*내용이 있을 때*/
  if (t.length !== 0) {
    if (d === true) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const storageAvailable = (type) => {
  try {
    var storage = window[type],
      x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  }
  catch(e) {
    return e instanceof DOMException && (
        // Firefox를 제외한 모든 브라우저
      e.code === 22 ||
      // Firefox
      e.code === 1014 ||
      // 코드가 존재하지 않을 수도 있기 때문에 테스트 이름 필드도 있습니다.
      // Firefox를 제외한 모든 브라우저
      e.name === 'QuotaExceededError' ||
      // Firefox
      e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // 이미 저장된 것이있는 경우에만 QuotaExceededError를 확인하십시오.
      storage.length !== 0;
  }
};

let toHtml = function(value){
  if(value){
    return { __html: value};
  }else{
    return false;
  }
};

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const getLocalStorageValue = (name) => {
  let value = "";
  if (storageAvailable("localStorage")) {
    const item = localStorage.getItem(name);
    if (item) {
      value = item;
    }
  }
  return value;
}

const gtmVirtualPageView = (rest) => {
  window.dataLayer?.push({
    event: 'pageView',
    ...rest,
  });
};

const reduxJpBase = ({ set_reducer, base_reducer = {}, root_saga }) => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [sagaMiddleware, thunk];
  const host = window.location.host !== "www.jobplanet.co.kr";
  const composeEnhancers =
    host && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;
  const enhancer = composeEnhancers(
    applyMiddleware(...middlewares)
    // other store enhancers if any
  );
  const store = createStore(set_reducer, base_reducer, enhancer);
  if (!isEmpty(root_saga)) {
    sagaMiddleware.run(root_saga);
  }
  return store;
};


export {
  reduxJpBase,
  isEmpty,
  autoCompleteCheck,
  storageAvailable,
  solution,
  toHtml,
  numberWithCommas,
  getLocalStorageValue,
  gtmVirtualPageView
};
