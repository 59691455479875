import ReactGA from "react-ga";

const getGAevent = (eventObj) => {
  ReactGA.event({
    category: "기업관리센터",
    ...eventObj
  });
};

export default getGAevent;
