import 'babel-polyfill';
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import { CookiesProvider } from "react-cookie";
import jpAmplitude from "./Helper/jpAmplitude";
import jpExperiment from "./Helper/experiment";
import jpbranchio from "./Helper/jpBranchIo";
import Highcharts from 'highcharts'
import HC_more from 'highcharts/highcharts-more'
HC_more(Highcharts);

jpexperiment = new jpExperiment();
jpamplitude = new jpAmplitude();
jpBranchIo = new jpbranchio();

ReactDOM.render(
  <CookiesProvider>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </CookiesProvider>,
  document.getElementById("jply_spa")
);
serviceWorker.unregister();
