import {PROCESS_STATUS} from "../constants/constants";

export default class Helper {
  static getBadgeInfo = (badge, dDay) => {
    let badgeInfo = {};

    switch (badge) {
      case PROCESS_STATUS.OPENED:
        if (dDay <= 7) {
          badgeInfo = {
            color: "badge_red",
            name: `D-${dDay}`,
          }
        } else {
          badgeInfo = {
            color: "badge_blue",
            name: `D-${dDay}`,
          }
        }
        break;
      case PROCESS_STATUS.PENDING:
        badgeInfo = {
          color: "badge_gray",
          name: "대기",
        }
        break;
      case PROCESS_STATUS.CLOSED:
        badgeInfo = {
          color: "badge_empty",
          name: "마감"
        }
        break;
      default:
        break;
    }
    return badgeInfo;
  };
}

