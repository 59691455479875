import { useQuery } from "react-query";
import axiosGet from "../../Helper/axiosGet";

const fetchReport = async () => {
  const { data } = await axiosGet({
    test_base_url: process.env.REACT_APP_BACKEND_HOST,
    url: "/api/v3/business_center/relay_signup/company_visit",
  });
  return data;
};

const useQueryReport = (option = {}) => useQuery("partnersReport", fetchReport);

export default useQueryReport;
