import React, { Component } from 'react';
import { isEmpty } from '../../Helper/gm';
import PropTypes from "prop-types";

class JplyCheckBox extends Component {
  constructor(props){
    super(props);
    this.state = {
      value: isEmpty(this.props.value) ? "" :  this.props.value
    }
  }
  static defaultProps = {
    value: false,
    name:'',
    keyName: '',
    input_id: '',
    label: '',
  }
  componentDidUpdate(prevProps){
    if(prevProps.value !== this.props.value){
      this.setState({
        value: this.props.value,
      })
    }
  }
  checkBoxOnChange(event){
    this.setState({
      value: event.target.checked,
    });
    this.props.onChecked(this.props.keyName, event.target.checked);
  }
  renderCheckbox(){
    return (
      <ul className="jply_checkbox_list">
        <li>
          <label className="jply_checkbox_box">
            <input
              type="checkbox"
              checked={this.state.value}
              disabled={isEmpty(this.props.disabled) ? false : true}
              className="jply_checkbox_item"
              name={this.props.input_id}
              id={this.props.input_id}
              value={this.state.value}
              onChange={(e) => {this.checkBoxOnChange(e)}}
            />
            <span className="checkbox_icon"></span>
            <span className="checkbox_text">{this.props.name}</span>
          </label>
        </li>
      </ul>
    );
  }
  render(){
    return (
      <div className="jply_ip_wrap">
        {
          this.props.label === '' ?
            '' : <div className="jply_ip_label">{this.props.label}</div>
        }
        {this.renderCheckbox()}
      </div>
    );
  }
}

JplyCheckBox.propTypes = {
  value: PropTypes.bool,
  onChecked: PropTypes.func.isRequired,
  name:PropTypes.string,
  keyName: PropTypes.string,
  input_id: PropTypes.string,
  label: PropTypes.string,
};

export default JplyCheckBox;
