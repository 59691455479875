import React, {} from  'react';
import { useQueryClient } from "react-query";
import useCompanyUser from "../hooks/useCompanyUser";
import getGAevent from "../lib/getGAevent";

const ModalUtilizationRate = ({modalClose}) => {
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData("utilizationRate");
  const { user } = useCompanyUser();
  const base_url = process.env.REACT_APP_BACKEND_HOST;

  if(!data || !data.data.items) return null;
  /* 채용공고, 대표 복지, 기업 추천 복지, 우리 기업만의 복지, 인사 담당자 연봉등록 목록에서 삭제 */
  const modifyItems = data?.data?.items?.filter(item => {
    return item.key_code !== 'job_postings' && !item.key_code.includes('benefits') && item.key_code !== 'official_salaries';
  }) || [];

  const total_count = data ? modifyItems.length : 0
  const in_use_count = data ?
    modifyItems.filter(({in_use, grade_type}) =>
      user.privilege_master_code !== "premium"
        ? grade_type !== 'paid' && in_use
        : in_use
    ).length : 0;

  const rate = data ? parseInt((in_use_count / total_count) * 100) : 0;


  const gaEventHandler = ({label}) => {
    getGAevent({
      action: "Popup_잡플래닛에서 다 되는 기업관리",
      label
    });
  };

  return (
    <div className="jply_popup layer_popup_box layer_popup_box_on">
      <div className="layer_popup_bg"></div>
      <div className={`layer_popup jply_modal_contents_ty modal_utilization_rate`} tabIndex={0}>
        <div className={`jply_modal_v2`}>
          <div className="modal_contents_wrap">
            <div className="modal_header">
              <div className="title">잡플래닛에서 다 되는 기업관리, 얼마나 활용하고 있을까요?</div>
            </div>
            <div className="modal_body">
              <div className="text_sub">
                현재 전체 기능 <em className="green">{total_count}개 중 {in_use_count}개 사용 중</em>이에요.
              </div>
              <div className="utilization_rate_box">
                <div className="utilization_rate_chart_bg">
                  <div className="utilization_rate_chart" style={{"width": `${rate}%`}}></div>
                </div>
                <em className="rate_text">{rate}%</em>
              </div>
              <div className="utilization_list_box">
                <div className="utilization_list_head">
                  <div className="utilization_tb_row">
                    <div className="utilization_tb_col"><span>기업 관리 기능</span></div>
                    <div className="utilization_tb_col"><span>사용 가능</span></div>
                    <div className="utilization_tb_col"><span>사용 중</span></div>
                    <div className="utilization_tb_col"><span>바로가기</span></div>
                  </div>
                </div>
                <div className="utilization_list_body">
                  {
                    modifyItems.map(({title, key_code, available, in_use, grade_type, link}, i) => {
                      return (
                        <div className="utilization_tb_row" key={i}>
                          <div className="utilization_tb_col">
                            <a
                              className={`utilization_link ${key_code === "hr_job_seekers" ? "bg_beta" : ""}`}
                              href={`${base_url + link}`}
                              onClick={() => {
                                gaEventHandler({
                                  label: title
                                })
                              }}>
                              <span>{title}</span>
                            </a>
                          </div>
                          <div className="utilization_tb_col">
                            { available ? <i className="jply_icon jp-circle col_green"></i> : <i className="jply_icon jp-x-circle col_red"></i> }
                          </div>
                          <div className="utilization_tb_col">
                            {
                              user.privilege_master_code !== "premium"
                                ? grade_type !== 'paid' && in_use
                                ? <i className="jply_icon jp-check-circle col_light_green"></i>
                                : <i className="jply_icon jp-x-circle col_red"></i>
                                : in_use
                                ? <i className="jply_icon jp-check-circle col_light_green"></i>
                                : <i className="jply_icon jp-x-circle col_red"></i>
                            }
                          </div>
                          <div className="utilization_tb_col">
                            <a
                              className={`${ ( available && !in_use ) ? "" : "utilization_link"}`}
                              href={`${base_url + link}`}
                              onClick={() => {
                                gaEventHandler({
                                  label: `${title}${ available && !in_use ? "_바로 시작하기" : ""}`
                                })
                              }}>
                              {
                                ( available && !in_use )
                                  ? <span className="jply_btn_xs ty_solid">바로 시작하기</span>
                                  : <i className="jply_icon jp-link-2 col_gray"></i>
                              }
                            </a>
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn_close_x_ty1"
            onClick={modalClose}>
            <span className="jply_icon jp-x" ></span>
            <span className="sr-only">close</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalUtilizationRate;




