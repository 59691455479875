import {
  all,
  put,
  call,
  takeLatest,
  takeEvery
} from "redux-saga/effects";
import apiGet from "../../Helper/axiosGet";
import { isEmpty } from "../../Helper/gm";
import { spinnerLoading, localStorageSet } from "../../sagas/saga_helper";
import loginCheckSage from "../../sagas/login_check_sage";

function* spinnerReset() {
  yield put({
    type: "SPA_SPINNER",
    addBy: 0
  });
}

/*스피너 동기 처리*/

function* secondCall(d, res) {
  const { key_list, option } = d;

  let new_url = `${option.url}?page=${
    isEmpty(option.page) ? 1 : option.page
  }&tab_type=${isEmpty(option.tab_type) ? 0 : option.page}`;

  key_list.map(
    d => (new_url += `&${d}=${JSON.stringify(res[`default_${d}`])}`)
  );

  const new_option = Object.assign(d, {
    option: {
      url: new_url
    }
  });

  yield call(workerSaga, new_option);
}

function* workerSaga(option) {
  try {
    /*api콜*/
    yield call(spinnerLoading, "on");
    const response = yield call(apiGet, option.option);
    yield call(spinnerLoading, "off");
    if (response.data.code > 299) {
      yield call(spinnerLoading, "end");
      console.log("500 Error");
    }
    if (isEmpty(option.modal_name)) {
      yield put({
        type: option.action,
        addBy: response.data,
        name: option.name
      });
    } else {
      yield put({
        type: option.action,
        addBy: response.data,
        name: option.name,
        top: option.top,
        modal_name: option.modal_name
      });
    }
    if (!isEmpty(option.second_api)) {
      yield call(secondCall, option.second_api, response.data.data);
    }
  } catch (error) {
    yield call(spinnerLoading, "end", error.response);
    if(!isEmpty(error.response.status)){
      if (error.response.status === 500) {
        console.log(error.response["status"], option);
        /*window.location.href = `${process.env.REACT_APP_BACKEND_HOST}`;*/
      }
    }

    localStorageSet("jobplanet_spa", "error");
    window.location.href = "/error";
  }
}

function* directAction(option) {
  if (isEmpty(option.modal_name)) {
    yield put({
      type: option.action,
      addBy: { data: option.active },
      name: option.name
    });
  } else {
    if (option.modal_name === "other_detail_modal") {
      yield put({
        type: option.action,
        name: option.name,
        addBy: { data: option.active },
        top: option.top,
        modal_name: option.modal_name
      });
    } else {
      yield put({
        type: option.action,
        name: option.name,
        addBy: { data: option.active },
        top: option.top,
        modal_name: option.modal_name
      });
    }
  }
}

export function* rootSaga() {
  yield all([
    yield takeEvery("SPA_SPINNER_RESET", spinnerReset),
    yield takeEvery("ARLI_SPINNER_CALL", spinnerLoading),
    yield takeLatest("LOGIN_CHECK", loginCheckSage),
    yield takeEvery("ARLI_CALL", workerSaga),
    yield takeEvery("SPA_CALL", workerSaga),
    yield takeEvery("ARLI_DIRECT", directAction)
  ]);
}
