import axios from "axios";
import {localStorageGet} from "../../sagas/saga_helper";

export default function fetchDog(option) {
    const { url, params } = option;
    return axios({
        method: "post",
        baseURL: process.env.REACT_APP_BACKEND_HOST,
        url,
        data: Object.assign({}, params, {access_token: localStorageGet('access_token')}),
        headers: {
            "X-CSRF-Token": window.ajaxtoken
        },
    });
}
