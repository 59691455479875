export default class jpExperiment {
  constructor(userId) {
    this.experimentInit(userId);
  }

  /* Experiment init 함수 */
  experimentInit = async (userId = "ss") => {
    window.experiment = Experiment && Experiment.Experiment.initializeWithAmplitudeAnalytics(process.env.REACT_APP_EXPERIMENTS_CLIENT_KEY);
    if (userId) {
      await experiment.fetch({
        user_id: 'JPL_' + userId,
      });
    } else {
      await experiment.fetch();
    }
  }

  /* 값을 받아서 테스팅 값을 리턴 받는 함수*/
  accessExperiment = (key) => {
    return experiment.variant(key);
  }
}
