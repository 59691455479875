import apiGet from "../../Helper/axiosGet";
import api from "../../Helper/axiosCommon";
import {getLocalStorageValue} from "../../Helper/gm";

const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;
const BASE_URL = `${BACKEND_HOST}/api/v5/partners/job`;
const JOB_URL = `${BASE_URL}/postings`;

export default class JobService {
  static getJobPostingSimpleList(page, per) {
    return this.getAPI({ url: `${JOB_URL}/summary?page=${page}&per=${per}` });
  }

  static getJobStatistics() {
    return this.getAPI({ url: `${BASE_URL}/statistics` });
  }

  static getJobPostingInfo({ status, order, page, per, keyword, occupation, isDownload }) {
    let paramList = `status=${status}&order=${order}&page=${page}&per=${per}`;
    let postingInfoUrl = JOB_URL;
    if (occupation.length > 0) {
      paramList = paramList.concat(`&occupation_id=${occupation.join(",")}`);
    }
    if (keyword) {
      paramList = paramList.concat(`&search_keyword=${encodeURIComponent(keyword)}`);
    }
    if (isDownload) {
      postingInfoUrl += ".csv";
    }
    return this.getAPI({ url: `${postingInfoUrl}?${paramList}` });
  }

  static getOccupationList(status) {
    return this.getAPI({ url: `${JOB_URL}/occupations?status=${status}` });
  }

  static async postJobPosting(postingId) {
    return this.callAPI({ method: "post", url: `${JOB_URL}/${postingId}/copy` });
  }

  static async patchJobPosting(postingId) {
    return this.callAPI({ method: "patch", url: `${JOB_URL}/${postingId}/close` });
  }

  static async deleteJobPosting(postingId) {
    return this.callAPI({ method: "delete", url: `${JOB_URL}/${postingId}` });
  }

  /* 공통 호출부: get */
  static async getAPI(options) {
    let headers = {};
    const bearerToken = getLocalStorageValue("access_token");
    if (bearerToken) {
      headers = {Authorization: `Bearer ${bearerToken}`};
    }

    const response = await apiGet({ ...options, headers })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        return err.response?.data;
      });
    return response;
  }

  /* 공통 호출부: post, patch, delete */
  static async callAPI(options) {
    let headers = {};
    const bearerToken = getLocalStorageValue("access_token");
    if (bearerToken) {
      headers = {Authorization: `Bearer ${bearerToken}`};
    }

    const response = await api({ ...options, headers })
      .then(response => {
        return response.data;
      })
      .catch(err => {
        console.log(err);
        return err.response?.data;
      });
    return response;
  }
}
