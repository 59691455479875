import React, { Component } from 'react';
import { css } from "react-emotion";

class JplySpinner extends Component {
  render(){
    return (
      <div id={this.props.id} className={`${this.props.size && this.props.size === "sm" ? loading_spinner_sm : loading_spinner} inline_spinner`}>
        <div className="loading_spinner_img bouncing-loader">
          <div className="bouncing-loader__round"></div>
          <div className="bouncing-loader__round"></div>
          <div className="bouncing-loader__round"></div>
        </div>
        <div className="loading_spinner_bg"></div>
      </div>
    )
  }
}

const loading_spinner = css`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  z-index: 1000;

  .loading_spinner_img {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -10px;
    margin-left: -40px;
    z-index: 1001;
  }
  .loading_spinner_bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: .4;
    z-index: 1000;
  }

  .bouncing-loader {
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;

    &__round {
      width: 20px;
      height: 20px;
      background-color: #00c362;
      border-radius: 50%;

      &:not(:first-child) {
        margin-left: 10px;
      }

      animation: bounce_spinner 0.6s infinite alternate;

      &:nth-child(2) {
        animation-delay: 0.2s;
      }

      &:nth-child(3) {
        animation-delay: 0.4s;
      }
    }
  }
`;

const loading_spinner_sm = css`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  z-index: 1000;

  .loading_spinner_img {
    z-index: 1001;
    margin-bottom: -12px;
  }

  .bouncing-loader {
    display: flex;
    display: -ms-flexbox;
    display: -webkit-flex;

    &__round {
      width: 12px;
      height: 12px;
      background-color: #1CBDA3;
      border-radius: 50%;

      &:not(:first-child) {
        margin-left: 10px;
      }

      animation: bounce_spinner 0.6s infinite alternate;

      &:nth-of-type(2) {
        animation-delay: -0.2s;
        -ms-animation-delay: -0.2;
        -webkit-animation-delay: -0.2s;
        animation-delay: -0.2s;
      }

      &:nth-of-type(3) {
        animation-delay: -0.4s;
        -ms-animation-delay: -0.4;
        -webkit-animation-delay: -0.4s;
        animation-delay: -0.4s;
      }
    }
  }
`

export default JplySpinner;
