import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

function ModalClaimReview(props) {
  const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;
  const dispatch = useDispatch();
  const modalInfo = useSelector(state => state.modal);
  const [selectValue, setSelectValue] = useState({
    id: 0,
    name: "",
    translate_key: "",
    order: 0,
    is_other: false,
    form_required: false,
    active: false,
    created_at: "",
    updated_at: ""
  });
  const [isComplete, setComplete] = useState(false);
  const [isValidate, setValidate] = useState(true);

  useEffect(() => {
    getClaimInfo();
  }, []);

  const getClaimInfo = useCallback(() => {
    dispatch({
      type: "GET_CLAIM_INFO",
      payload: {
        url: `/api/v1/report_reasons`
      },
    });
  }, [dispatch]);

  const setClaimInfo = useCallback(() => {
    if (selectValue.id) {
      const {reviewId} = props;
      dispatch({
        type: "SET_CLAIM_INFO",
        payload: {
          url: `${BACKEND_HOST}/api/v1/welfare/welfare_reviews/${reviewId}/report`,
          params: {
            reason: selectValue.id
          }
        },
      });
      setComplete(true);
    } else {
      setValidate(false);
    }
  }, [dispatch, selectValue]);

  const closeModal = useCallback(() => {
    dispatch({
      type: "SET_MODAL",
      payload: {
        show: false,
      },
    });
  }, [dispatch]);

  const handleChangeEvent = (value) => {
    if (value) {
      setValidate(true);
      setSelectValue(value);
    }
  }

  const setAdditionalInfo = () => {
    if(window.confirm("추가 정보를 입력하시겠습니까?")) {
      window.location.href=`${BACKEND_HOST}/reports/new?reportable_id=${props.reviewId}&reportable_type=welfare_review&report_reason_id=${selectValue.id}`;
    }
    return false;
  }

  return (
    <div>
      {modalInfo?.data && (
        <div className="jply_popup layer_popup_box layer_popup_box_on">
          <div className="layer_popup_bg" />
          <div className="layer_popup claim_modal">
            <div className={`jply_modal_v2 ${isComplete ? "complete_modal" : "review_modal"}`}>
              <div className="report_reviews" style={{display: `${isComplete ? "none" : ""}`}}>
                <div className="tit">
                  <h2>신고하기</h2>
                  <button type="button" className="close" onClick={closeModal}>닫기</button>
                </div>
                <div className="report_content com_signUp">
                  <h3>신고하려는 이유를 선택해주세요</h3>
                  <div className={`label_wrap ${isValidate ? "" : "is_error"}`}>
                    {modalInfo.data.map((reason) => {
                      return (
                        <label className="btn_radio" key={reason.id}>
                          <input type="radio"
                                 className="csradio"
                                 name={`reason_radio_${reason.id}`}
                                 value={reason.id}
                                 checked={selectValue.id === reason.id}
                                 onChange={() => {handleChangeEvent(reason)}} />
                          <span className="radio_icon"></span>
                          <span className="radio_text">{reason.name}</span>
                        </label>
                      )
                    })}
                    {selectValue?.form_required && (
                      <dl className="notice form_required_guide">
                        <dt>신고하기 전에 잠깐!</dt>
                        <dd>신고하는 게시글에 대하여 블라인드(숨김 처리) 또는 법적인 조치가 필요할 경우에는 추가 정보가 필요합니다.</dd>
                      </dl>
                    )}
                  </div>
                </div>
                <div className="btn_group">
                  <button type="button" className="report_reviews__close" onClick={closeModal}>취소</button>
                  {selectValue?.form_required ? (
                    <button type="button" className="report_reviews__add" onClick={setAdditionalInfo}>추가 정보 입력</button>
                  ) : (
                    <button type="button" className="report_reviews__submit" onClick={setClaimInfo}>신고</button>
                  )}
                </div>
              </div>
              <div className="report_complete" style={{display: `${isComplete ? "" : "none"}`}}>
                <div className="tit">
                  <h2>신고가 접수되었습니다.</h2>
                  <button type="button" onClick={closeModal}>닫기</button>
                </div>
                <div className="report_content">
                  <p>신고된 게시물은 내부 검토가 진행되며, 검토 결과에 따라 처리가 됩니다.</p>
                </div>
                <div className="btn_group">
                  <button type="button" className="jply_btn_sm ty_solid" onClick={closeModal}>확인</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ModalClaimReview;
