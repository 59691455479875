/* reducer은 action에 의해서 변경된 state를 받아 어플리케이션의 화면 또는 상태가 어떻게 변화할 것인지를 정의함 */
/* 리덕스에서 state는 read-only로서 action 객체가 dispatch(보내다) 되어야만 변경될 수 있습니다. */
/* 초기 상태 정의 */

const loading = (state= 0, action) => {
  switch (action.type) {
    case "SPA_SPINNER":
      return action.addBy;
    default:
      return state;
  }
};

const modal = ( state={}, action ) => {
  switch (action.type){
    case "PARTNERS_SET_MODAL":
      return {
        ...state,
        ...action.addBy,
      };
    case "SET_MODAL_INFO":
      return {
        ...state,
        ...action.addBy,
      };
    case "MODALL_OPEN":
      return Object.assign({}, state, {
        show: true,
        modal_name: action.modal_name,
        top: action.top,
        [action.name]: action.addBy.data
      });
    case "MODALL_CLOSE":
      return Object.assign({}, state, {
        show: false,
        [action.name]: null
      });
    default: return state;
  }
};

const user = (state = {}, action) => {
  switch (action.type){
    case "SET_USER":
      return {
        ...state,
        ...action.addBy
      };
      
    default: return state;
  }
};

export {
  modal,
  loading,
  user
};
