const API_PATH = process.env.REACT_APP_API_PATH;
const initialState = {
  loading:0,
  alri: {
    api_url:{
      business_status: `${API_PATH}/dash_board/business_status`,
      jp_detail: `${API_PATH}/dash_board/jp_detail`,
      jp_contents: `${API_PATH}/dash_board/jp_contents`,
      tab_contents:`${API_PATH}/dash_board/tab_contents`,
      blind_detail: `${API_PATH}/dash_board/blind_detail`,
      issues: `${API_PATH}/dash_board/issues`,
      offtherecord_filter: `${API_PATH}/off_the_record/filters`,
      offtherecord_contents: `${API_PATH}/off_the_record/contents`,
      severity_keywords: `${API_PATH}/category/severity_keywords`,
      category_analysis: `${API_PATH}/category/category_analysis`,
      contents_analysis: `${API_PATH}/category/contents_analysis`,
      categories: `${API_PATH}/category/categories`,
      detect_words: `${API_PATH}/keyword/detect_words`,
      keyword_jp_chart: `${API_PATH}/keyword/jp_chart`,
      keyword_jp_contents: `${API_PATH}/keyword/jp_contents`,
      keyword_social_chart: `${API_PATH}/keyword/social_chart`,
      keyword_social_contents: `${API_PATH}/keyword/social_contents`,
      analysis_total: `${API_PATH}/analysis/total`,
      analysis_calc_range: `${API_PATH}/analysis/calc_range`,
      analysis_social: `${API_PATH}/analysis/social`,
    },
    loading: 0,
    changedColor: "#f5f5f5",
    activeMenu: "온라인 검사",
    online: {
      pageTitle:{
        type: "default",
        mainTitle: "온라인 검사결과",
        active: "",
        text: "",
      }
    },
    analysis:{
      range: "6개월",
      target: "(주)엘지유플러스",
      company:{
        graphDetails: {
          items: [
            {
              linkTo: "#",
              title: "조직문화",
              percentage: "21",
            },
            {
              linkTo: "#",
              title: "경영/운영",
              percentage: "25",
            },
            {
              linkTo: "#",
              title: "불공정",
              percentage: "25",
            },
            {
              linkTo: "#",
              title: "면접/파트너",
              percentage: "29",
            },
          ]
        },
        alert: {
          items: [
            {
              level: "위험",
              number: "<strong class=\"count_number emph_red\"id=\"severity_high_count\">282</strong> <span>건</span>"
            },
            {
              level: "경고",
              number: "<strong class=\"count_number emph_orange\" id=\"severity_medium_count\">271</strong> <span>건</span>"
            },
            {
              level: "주의",
              number: "<strong class=\"count_number emph_light_green\" id=\"severity_low_count\">123</strong> <span>건</span>"
            },
          ]
        },
        noticeItems:{
          items:[
            "<p class=\"notice_text\"><a href=\"/alri/category/100?range=six-month\">\"조직문화\"</a>에서 <a href=\"/alri/category/100/keyword/역차별?range=six-month\" class=\"emph_orange\"><em class=\"emph_orange\">\"역차별\"</em></a> 외 <em class=\"emph_red\">18개</em> 키워드로 <a href=\"/alri/category/100?range=six-month\"><em class=\"emph_orange\">58건</em></a>의 컨텐츠가 감지됨.</p>",
            "<p class=\"notice_text\"><a href=\"/alri/category/200?range=six-month\">\"경영/운영\"</a>에서 <a href=\"/alri/category/200/keyword/대책?range=six-month\" class=\"emph_light_green\"><em class=\"emph_light_green\">\"대책\"</em></a> 외 <em class=\"emph_red\">15개</em> 키워드로 <a href=\"/alri/category/200?range=six-month\"><em class=\"emph_light_green\">71건</em></a>의 컨텐츠가 감지됨.</p>",
            "<p class=\"notice_text\"><a href=\"/alri/category/300?range=six-month\">\"불공정\"</a>에서 <a href=\"/alri/category/300/keyword/국정감사?range=six-month\" class=\"emph_orange\"><em class=\"emph_orange\">\"국정감사\"</em></a> 외 <em class=\"emph_red\">16개</em> 키워드로 <a href=\"/alri/category/300?range=six-month\"><em class=\"emph_orange\">70건</em></a>의 컨텐츠가 감지됨.</p>",
            "<p class=\"notice_text\"><a href=\"/alri/category/400?range=six-month\">\"면접/파트너\"</a>에서 <a href=\"/alri/category/400/keyword/정보유출?range=six-month\" class=\"emph_red\"><em class=\"emph_red\">\"정보유출\"</em></a> 외 <em class=\"emph_red\">3개</em> 키워드로 <a href=\"/alri/category/400?range=six-month\"><em class=\"emph_red\">87건</em></a>의 컨텐츠가 감지됨.</p>"
          ]
        },
        resultComment:{
          typeA:
            "최근 <em class=\"emph_deep_green\">6개월</em> 동안 리스크 컨텐츠 <em class=\"emph_deep_green\" id=\"total_count\">58건</em>이 감지되었으며,<br><a href=\"/alri/category/400?range=six-month\" id=\"top_category_name\"><em class=\"emph_deep_green\">\"불공정\"</em></a> 영역의 리스크 컨텐츠가 <em class=\"emph_deep_green\" id=\"top_category_percentage\">80%</em>를 차지합니다.",
        }
      },
      period:{
        sectionTitle:{
          title: "기간별 검사 결과",
          description:"",
        },
        totalKeywordList: {
          title:"전체 키워드 목록",
          description:"영역별로 감지된 키워드를 표시합니다. 키워드를 클릭하면 분석 내용과 상세 컨텐츠를 보실 수 있습니다",
          items:[
            {
              title: "조직문화",
              totalCount: "19",
              items: [
                {
                  level: "tag_danger",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "자살",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "차별",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "무시",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "스트레스",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "군대문화",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "강제",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "역차별",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "철야",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "초과근무",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "횡포",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "사내정치",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "불만족",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "야근",
                },
                {
                  level: "tag_caution",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "악질",
                },
                {
                  level: "tag_caution",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "무능",
                },
                {
                  level: "tag_caution",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "압박",
                },
                {
                  level: "tag_caution",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "지옥",
                },
                {
                  level: "tag_caution",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "소모품",
                },
                {
                  level: "tag_caution",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "쓰레기",
                }
              ]
            },
            {
              title: "면접/파트너",
              totalCount: "4",
              items: [
                {
                  level: "tag_danger",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "협력사",
                },
                {
                  level: "tag_danger",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "정보유출",
                },
                {
                  level: "tag_danger",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "수탁사",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "거짓",
                },
              ]
            },
            {
              title: "경영/운영",
              totalCount: "16",
              items: [
                {
                  level: "tag_danger",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "사망",
                },
                {
                  level: "tag_danger",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "불매",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "열정페이",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "퇴사",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "계약직",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "서비스장애",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "정리해고",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "불만족",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "독단",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "갑질",
                },
                {
                  level: "tag_caution",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "비추천",
                },
                {
                  level: "tag_caution",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "실적압박",
                },
                {
                  level: "tag_caution",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "대책",
                },
                {
                  level: "tag_caution",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "노조",
                },
                {
                  level: "tag_caution",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "노답",
                },
                {
                  level: "tag_caution",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "고인물",
                },
              ]
            },
            {
              title: "불공정",
              totalCount: "16",
              items: [
                {
                  level: "tag_danger",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "리베이트",
                },
                {
                  level: "tag_danger",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "소송",
                },
                {
                  level: "tag_danger",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "고소",
                },
                {
                  level: "tag_danger",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "고발",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "담합",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "독과점",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "국정감사",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "부당영업",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "소환조사",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "과징금",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "불공정",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "협박",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "수수료",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "불법",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "꼼수",
                },
                {
                  level: "tag_caution",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "노답",
                },
              ]
            },
          ]
        },
        resultContent:{
          items: [
            {
              linkTo: "/alri/category/100?range=six-month",
              title: "조직문화",
              content: "최근 6개월 동안 <a href=\"/alri/category/100/keyword/역차별?range=six-month\" class=\"emph_orange\"><em class=\"emph_orange\">\"역차별\"</em></a> 외 <em class=\"emph_red\">29개</em> 키워드로 총 <a href=\"/alri/category/100?range=six-month\"><em class=\"emph_orange\">105건</em></a>의 컨텐츠가 감지되었습니다. <em class=\"caution\">주의성</em> 컨텐츠가 <em class=\"caution\">58건</em>으로 가장 많습니다."
            },
            {
              linkTo: "/alri/category/200?range=six-month",
              title: "경영/운영",
              content:
                "최근 6개월 동안 <a href=\"/alri/category/200/keyword/노조?range=six-month\" class=\"emph_light_green\"><em class=\"emph_light_green\">\"노조\"</em></a> 외 <em class=\"emph_red\">11개</em> 키워드로 총 <a href=\"/alri/category/200?range=six-month\"><em class=\"emph_light_green\">19건</em></a>의 컨텐츠가 감지되었습니다. <em class=\"warning\">경고성</em> 컨텐츠가 <em class=\"warning\">10건</em>으로 가장 많습니다.",
            },
            {
              linkTo: "/alri/category/300?range=six-month",
              title: "불공정",
              content:
                "최근 6개월 동안 <a href=\"/alri/category/300/keyword/독과점?range=six-month\" class=\"emph_orange\"><em class=\"emph_orange\">\"독과점\"</em></a> 키워드로 총 <a href=\"/alri/category/300?range=six-month\"><em class=\"emph_orange\">1건</em></a>의 컨텐츠가 감지되었습니다. <em class=\"warning\">경고성</em> 컨텐츠가 <em class=\"warning\">1건</em>으로 가장 많습니다.",
            },
            {
              linkTo:"/alri/category/400?range=six-month",
              title: "면접/파트너",
              content:
                "최근 6개월 동안 감지된 컨텐츠가 없습니다. 이 영역은 <em class=\"safe\">안전</em> 상태입니다."
            }
          ]
        },
        resultComment:{
          typeA:
            "최근 <em class=\"emph_deep_green\">6개월</em> 동안 리스크 컨텐츠가 가장 많이 감지된 채널은 <em class=\"emph_deep_green\" id=\"top_channel\">트위터 (105건, 34%)</em>입니다.",
          typeB:
            "트위터는 이미 많은 사용자들에게 이슈가 되었을 것으로 판단됩니다. 해당 영역에 대한 적극적인 대응으로 더 많은 확산을 막아야 합니다.",
        }
      }
    },
    category:{
      pageTitle:{
        type: "list",
        mainTitle: "영역별 진단",
        active: "조직문화",
        text: {
          items: [
            {
              linkTo: "#",
              innerText: "조직문화",
            },
            {
              linkTo: "#",
              innerText: "면접/파트너",
            },
            {
              linkTo: "#",
              innerText: "경영/운영",
            },
            {
              linkTo: "#",
              innerText: "불공정",
            }
          ]
        },
      },
      totalKeywordList: {
        title:"전체 키워드 목록",
        description:"영역별로 감지된 키워드를 표시합니다. 키워드를 클릭하면 분석 내용과 상세 컨텐츠를 보실 수 있습니다",
        items:[
          {
            title: "조직문화",
            totalCount: "19",
            items: [
              {
                level: "tag_danger",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "자살",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "차별",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "무시",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "스트레스",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "군대문화",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "강제",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "역차별",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "철야",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "초과근무",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "횡포",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "사내정치",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "불만족",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "야근",
              },
              {
                level: "tag_caution",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "악질",
              },
              {
                level: "tag_caution",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "무능",
              },
              {
                level: "tag_caution",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "압박",
              },
              {
                level: "tag_caution",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "지옥",
              },
              {
                level: "tag_caution",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "소모품",
              },
              {
                level: "tag_caution",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "쓰레기",
              }
            ]
          },
          {
            title: "면접/파트너",
            totalCount: "4",
            items: [
              {
                level: "tag_danger",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "협력사",
              },
              {
                level: "tag_danger",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "정보유출",
              },
              {
                level: "tag_danger",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "수탁사",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "거짓",
              },
            ]
          },
          {
            title: "경영/운영",
            totalCount: "16",
            items: [
              {
                level: "tag_danger",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "사망",
              },
              {
                level: "tag_danger",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "불매",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "열정페이",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "퇴사",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "계약직",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "서비스장애",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "정리해고",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "불만족",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "독단",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "갑질",
              },
              {
                level: "tag_caution",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "비추천",
              },
              {
                level: "tag_caution",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "실적압박",
              },
              {
                level: "tag_caution",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "대책",
              },
              {
                level: "tag_caution",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "노조",
              },
              {
                level: "tag_caution",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "노답",
              },
              {
                level: "tag_caution",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "고인물",
              },
            ]
          },
          {
            title: "불공정",
            totalCount: "16",
            items: [
              {
                level: "tag_danger",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "리베이트",
              },
              {
                level: "tag_danger",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "소송",
              },
              {
                level: "tag_danger",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "고소",
              },
              {
                level: "tag_danger",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "고발",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "담합",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "독과점",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "국정감사",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "부당영업",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "소환조사",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "과징금",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "불공정",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "협박",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "수수료",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "불법",
              },
              {
                level: "tag_warning",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "꼼수",
              },
              {
                level: "tag_caution",
                linkTo: "/alri/category/100/keyword/자살?range=six-month",
                text: "노답",
              },
            ]
          },
        ]
      },
      items:[
        {
          dataTab: '조직문화',
          keywords: {
            sectionTitle: {
              title: "위험도별 키워드",
              description: "",
            },
            keyword:{
              items:[
                {
                  level: "tag_danger",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "성희롱",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "스트레스",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "야근",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "차별",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "사내정치",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "초과근무",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "군대문화",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "횡포",
                },
                {
                  level: "tag_caution",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "압박",
                },
                {
                  level: "tag_caution",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "무능",
                },
                {
                  level: "tag_caution",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "소모품",
                },
              ],
            },
            resultComment:{
              typeA:
                "<em class=\"emph_deep_green\">최근 6개월</em> 동안 <em class=\"emph_deep_green\"><a href=\"/alri/category/100/keyword/%EC%84%B1%ED%9D%AC%EB%A1%B1?range=six-month\" style=\"color: rgb(253, 94, 82);\">성희롱</a> 외 11개 키워드</em>로 <em class=\"emph_deep_green\">25건</em>의 컨텐츠가 감지되었습니다.<br><em class=\"emph_deep_green\">경고성</em> 컨텐츠가 <em class=\"emph_deep_green\">12건</em> 으로 가장 많습니다.",
              typeB:
                "<span><a href=\"/alri/category/100/keyword/%EC%95%95%EB%B0%95?range=six-month\" style=\"color:#7ed321;\">노조</a></span>,<span><a href=\"/alri/category/100/keyword/%EC%8A%A4%ED%8A%B8%EB%A0%88%EC%8A%A4?range=six-month\" style=\"color:#F5A623;\">야근</a></span>,<span><a href=\"/alri/category/100/keyword/%EC%95%BC%EA%B7%BC?range=six-month\" style=\"color:#F5A623;\">초과근무</a></span> 등 키워드의 컨텐츠가 자주 감지되고 있습니다. '조직문화' 영역의 키워드에 대한 관심이 필요합니다.",
            }
          },
          content:{
            sectionTitle: {
              title: "컨텐츠 분석",
              description: "",
            },
            pieGraph:{
              totalCount: 19,
              career: {
                item: [
                  {
                    title: "영업제휴",
                    percentage: "42",
                  },
                  {
                    title: "서비스/고객지원",
                    percentage: "24",
                  },
                  {
                    title: "IT/인터넷",
                    percentage: "14",
                  },
                  {
                    title: "인사/총무",
                    percentage: "10",
                  },
                  {
                    title: "전문직",
                    percentage: "5",
                  },
                  {
                    title: "마케팅/시장조사",
                    percentage: "5",
                  },
                ]
              },
              employmentType:{
                item: [
                  {
                    title: "정규직",
                    percentage: "38",
                  },
                  {
                    title: "계약직",
                    percentage: "24",
                  },
                  {
                    title: "아르바이트",
                    percentage: "14",
                  },
                  {
                    title: "인턴직",
                    percentage: "10",
                  },
                  {
                    title: "프리랜서",
                    percentage: "10",
                  },
                ]
              },
              resultComment:"<em class=\"emph_deep_green\">잡플래닛</em> 리스크 컨텐츠는 총 <em class=\"emph_deep_green\">21건</em>이며, <em class=\"emph_deep_green\">영업/제휴 직군/정규직</em>이 각각 가장 많았습니다.",
            },
            barGraph:{
              totalCount: 4,
              resultComment:"<em class=\"emph_deep_green\">SNS/커뮤니티</em> 리스크 컨텐츠는 총 <em class=\"emph_deep_green\">4건</em>이며, <em class=\"emph_deep_green\">트위터와 블로그</em>에서 가장 많이 모니터링 되고 있습니다.",
            }
          },
          keywordDetails:{
            pageTitle:{
              type: "default",
              text: "키워드 상세 분석",
            },
            items: [
              {
                keyword: "성희롱",
                level: "label_danger",
                levelText: "경고",
                totalCountClass:"emph_red",
                totalCount: 0,
                sectionTitle: {
                  title: "성희롱 - 조직문화",
                  description: "",
                },
                pieGraph:{
                  totalCount: 2,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "alri_keyword_review keyword_danger",
                    totalCount: 2,
                    item:[
                      {
                        dataItemKey: "996018",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "이 많으며 윗사람 눈치를 보고 일해야 한다. 개인적으로 별로다 신규 사업...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "이번 테스트는 인터뷰 폭행 입니다.",
                            documentInfo: "최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            }
                          }
                        }
                      },
                      {
                        dataItemKey: "996019",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "가끔 <em class=\"emph_orange\">야근</em>합니다. 부장님이 술먹으러 가자고 하면 네에... 하고 가야합니다. 꼰...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                    ],
                  },
                },
                barGraph:{
                  totalCount: 10,
                  listClass: "alri_keyword_review keyword_danger",
                  item: [
                    {
                      social: "[블로그]",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    },
                    {
                      social: "[블로그]",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[블로그]",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[블로그]",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                  ]
                }
              },
              {
                keyword: "스트레스",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 10,
                sectionTitle: {
                  title: "스트레스 - 조직문화",
                  description: "",
                },
                pieGraph:{
                  totalCount: 0,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    totalCount: 0,
                    listClass: "",
                    item:[],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      type: "link",
                      social: "[블로그]",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      type: "link",
                      social: "[트위터]",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "야근",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 1,
                sectionTitle: {
                  title: "야근 - 조직문화",
                  description: "",
                },
                pieGraph:{
                  totalCount: 0,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "alri_keyword_review keyword_warning",
                    totalCount: 2,
                    item:[
                      {
                        dataItemKey: "996020",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "이 많으며 윗사람 눈치를 보고 일해야 한다. 개인적으로 별로다 신규 사업...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                      {
                        dataItemKey: "996021",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "가끔 <em class=\"emph_orange\">야근</em>합니다. 부장님이 술먹으러 가자고 하면 네에... 하고 가야합니다. 꼰...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                    ],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      type: "link",
                      social: "[블로그]",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46",
                      content: "",
                    },
                    {
                      type: "link",
                      social: "[트위터]",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "차별",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 0,
                sectionTitle: {
                  title: "차별 - 조직문화",
                  description: "",
                },
                pieGraph:{
                  totalCount: 0,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "alri_keyword_review keyword_warning",
                    totalCount: 0,
                    item:[
                      {
                        dataItemKey: "996020",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "이 많으며 윗사람 눈치를 보고 일해야 한다. 개인적으로 별로다 신규 사업...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                      {
                        dataItemKey: "996021",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "가끔 <em class=\"emph_orange\">야근</em>합니다. 부장님이 술먹으러 가자고 하면 네에... 하고 가야합니다. 꼰...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                    ],
                  },
                },
                barGraph:{
                  totalCount: 0,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "사내정치",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 0,
                sectionTitle: {
                  title: "사내정치 - 조직문화",
                  description: "",
                },
                pieGraph:{
                  totalCount: 2,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "alri_keyword_review keyword_warning",
                    totalCount: 2,
                    item:[
                      {
                        dataItemKey: "996022",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "이 많으며 윗사람 눈치를 보고 일해야 한다. 개인적으로 별로다 신규 사업...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                      {
                        dataItemKey: "996021",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "가끔 <em class=\"emph_orange\">야근</em>합니다. 부장님이 술먹으러 가자고 하면 네에... 하고 가야합니다. 꼰...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                    ],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "초과근무",
                level: "label_warning",
                type: "link",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: "0",
                sectionTitle: {
                  title: "초과근무 - 조직문화",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "",
                    totalCount: 0,
                    item:[],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "군대문화",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 0,
                sectionTitle: {
                  title: "군대문화 - 조직문화",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "alri_keyword_review keyword_warning",
                    totalCount: 2,
                    item:[
                      {
                        dataItemKey: "996024",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "이 많으며 윗사람 눈치를 보고 일해야 한다. 개인적으로 별로다 신규 사업...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                      {
                        dataItemKey: "996025",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "가끔 <em class=\"emph_orange\">야근</em>합니다. 부장님이 술먹으러 가자고 하면 네에... 하고 가야합니다. 꼰...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                    ],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "횡포",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 0,
                sectionTitle: {
                  title: "횡포 - 조직문화",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "alri_keyword_review keyword_warning",
                    totalCount: 2,
                    item:[
                      {
                        dataItemKey: "996030",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "이 많으며 윗사람 눈치를 보고 일해야 한다. 개인적으로 별로다 신규 사업...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                      {
                        dataItemKey: "996035",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "가끔 <em class=\"emph_orange\">야근</em>합니다. 부장님이 술먹으러 가자고 하면 네에... 하고 가야합니다. 꼰...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                    ],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "압박",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 0,
                sectionTitle: {
                  title: "압박 - 조직문화",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "alri_keyword_review keyword_warning",
                    totalCount: 2,
                    item:[
                      {
                        dataItemKey: "996054",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "이 많으며 윗사람 눈치를 보고 일해야 한다. 개인적으로 별로다 신규 사업...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                      {
                        dataItemKey: "996067",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "가끔 <em class=\"emph_orange\">야근</em>합니다. 부장님이 술먹으러 가자고 하면 네에... 하고 가야합니다. 꼰...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                    ],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "무능",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 0,
                sectionTitle: {
                  title: "무능 - 조직문화",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "",
                    totalCount: 0,
                    item:[],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "소모품",
                level: "label_caution",
                levelText: "주의",
                totalCountClass:"emph_light_green",
                totalCount: 0,
                sectionTitle: {
                  title: "소모품 - 조직문화",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "alri_keyword_review keyword_warning",
                    totalCount: 2,
                    item:[
                      {
                        dataItemKey: "996086",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "이 많으며 윗사람 눈치를 보고 일해야 한다. 개인적으로 별로다 신규 사업...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                      {
                        dataItemKey: "996083",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "가끔 <em class=\"emph_orange\">야근</em>합니다. 부장님이 술먹으러 가자고 하면 네에... 하고 가야합니다. 꼰...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                    ],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_caution",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              }
            ]
          },
        },
        {
          dataTab: '면접/파트너',
          keywords: {
            sectionTitle: {
              title: "위험도별 키워드",
              description: "",
            },
            keyword:{
              items:[
                {
                  level: "tag_danger",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "성희롱",
                },
                {
                  level: "tag_caution",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "소모품",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "차별",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "사내정치",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "횡포",
                },
                {
                  level: "tag_caution",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "압박",
                },
                {
                  level: "tag_caution",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "무능",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "초과근무",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "군대문화",
                },
              ],
            },
            resultComment:{
              typeA:
                "<em class=\"emph_deep_green\">최근 6개월</em> 동안 <em class=\"emph_deep_green\"><a href=\"/alri/category/100/keyword/%EC%84%B1%ED%9D%AC%EB%A1%B1?range=six-month\" style=\"color: rgb(253, 94, 82);\">성희롱</a> 외 11개 키워드</em>로 <em class=\"emph_deep_green\">25건</em>의 컨텐츠가 감지되었습니다.<br><em class=\"emph_deep_green\">경고성</em> 컨텐츠가 <em class=\"emph_deep_green\">12건</em> 으로 가장 많습니다.",
              typeB:
                "<span><a href=\"/alri/category/100/keyword/%EC%95%95%EB%B0%95?range=six-month\" style=\"color:#7ed321\">압박</a></span>,<span><a href=\"/alri/category/100/keyword/%EC%8A%A4%ED%8A%B8%EB%A0%88%EC%8A%A4?range=six-month\" style=\"color:#F5A623\">스트레스</a></span>,<span><a href=\"/alri/category/100/keyword/%EC%95%BC%EA%B7%BC?range=six-month\" style=\"color:#F5A623\">야근</a></span> 등 키워드의 컨텐츠가 자주 감지되고 있습니다. '조직문화' 영역의 키워드에 대한 관심이 필요합니다.",
            }
          },
          content:{
            sectionTitle: {
              title: "컨텐츠 분석",
              description: "",
            },
            pieGraph:{
              totalCount: 20,
              career: {
                item: [
                  {
                    title: "영업제휴",
                    percentage: "25",

                  },
                  {
                    title: "서비스/고객지원",
                    percentage: "42",
                  },
                  {
                    title: "IT/인터넷",
                    percentage: "41",
                  },
                  {
                    title: "인사/총무",
                    percentage: "20",
                  },
                  {
                    title: "전문직",
                    percentage: "52",
                  },
                  {
                    title: "마케팅/시장조사",
                    percentage: "54",
                  },
                ]
              },
              employmentType:{
                item: [
                  {
                    title: "정규직",
                    percentage: "33",
                  },
                  {
                    title: "계약직",
                    percentage: "22",
                  },
                  {
                    title: "아르바이트",
                    percentage: "15",
                  },
                  {
                    title: "인턴직",
                    percentage: "16",
                  },
                  {
                    title: "프리랜서",
                    percentage: "11",
                  },
                ]
              },
              resultComment:"<em class=\"emph_deep_green\">잡플래닛</em> 리스크 컨텐츠는 총 <em class=\"emph_deep_green\">20건</em>이며, <em class=\"emph_deep_green\">영업/제휴 직군/정규직</em>이 각각 가장 많았습니다.",
            },
            barGraph:{
              totalCount: 4,
              resultComment:"<em class=\"emph_deep_green\">SNS/커뮤니티</em> 리스크 컨텐츠는 총 <em class=\"emph_deep_green\">10건</em>이며, <em class=\"emph_deep_green\">트위터와 블로그</em>에서 가장 많이 모니터링 되고 있습니다.",
            }
          },
          keywordDetails:{
            pageTitle:{
              type: "default",
              text: "키워드 상세 분석",
            },
            items: [
              {
                keyword: "성희롱",
                level: "label_danger",
                levelText: "위험",
                totalCountClass:"emph_red",
                totalCount: 0,
                sectionTitle: {
                  title: "성희롱 - 면접/파트너",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "alri_keyword_review keyword_danger",
                    totalCount: 2,
                    item:[
                      {
                        dataItemKey: "996124",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "이 많으며 윗사람 눈치를 보고 일해야 한다. 개인적으로 별로다 신규 사업...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                      {
                        dataItemKey: "996125",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "가끔 <em class=\"emph_orange\">야근</em>합니다. 부장님이 술먹으러 가자고 하면 네에... 하고 가야합니다. 꼰...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                    ],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_danger",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "소모품",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 0,
                sectionTitle: {
                  title: "소모품 - 면접/파트너",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "",
                    totalCount: 0,
                    item:[],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "차별",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 1,
                sectionTitle: {
                  title: "차별 - 면접/파트너",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "alri_keyword_review keyword_warning",
                    totalCount: 2,
                    item:[
                      {
                        dataItemKey: "996324",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "이 많으며 윗사람 눈치를 보고 일해야 한다. 개인적으로 별로다 신규 사업...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                      {
                        dataItemKey: "996425",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "가끔 <em class=\"emph_orange\">야근</em>합니다. 부장님이 술먹으러 가자고 하면 네에... 하고 가야합니다. 꼰...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                    ],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "사내정치",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 0,
                sectionTitle: {
                  title: "사내정치 - 면접/파트너",
                  description: "",
                },
                pieGraph:{
                  totalCount: 0,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "",
                    totalCount: 0,
                    item:[],
                  },
                },
                barGraph:{
                  totalCount: 0,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "횡포",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 12,
                sectionTitle: {
                  title: "횡포 - 면접/파트너",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "alri_keyword_review keyword_warning",
                    totalCount: 2,
                    item:[
                      {
                        dataItemKey: "996524",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "이 많으며 윗사람 눈치를 보고 일해야 한다. 개인적으로 별로다 신규 사업...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                      {
                        dataItemKey: "996315",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "가끔 <em class=\"emph_orange\">야근</em>합니다. 부장님이 술먹으러 가자고 하면 네에... 하고 가야합니다. 꼰...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                    ],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "압박",
                level: "label_caution",
                levelText: "주의",
                totalCountClass:"emph_light_green",
                totalCount: 0,
                sectionTitle: {
                  title: "압박 - 면접/파트너",
                  description: "",
                },
                pieGraph:{
                  totalCount: 0,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "",
                    totalCount: 0,
                    item:[],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_caution",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "무능",
                level: "label_caution",
                levelText: "주의",
                totalCountClass:"emph_light_green",
                totalCount: 0,
                sectionTitle: {
                  title: "무능 - 면접/파트너",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "alri_keyword_review keyword_warning",
                    totalCount: 3,
                    item:[
                      {
                        dataItemKey: "997024",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "이 많으며 윗사람 눈치를 보고 일해야 한다. 개인적으로 별로다 신규 사업...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                      {
                        dataItemKey: "997425",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "가끔 <em class=\"emph_orange\">야근</em>합니다. 부장님이 술먹으러 가자고 하면 네에... 하고 가야합니다. 꼰...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                      {
                        dataItemKey: "997725",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "가끔 <em class=\"emph_orange\">야근</em>합니다. 부장님이 술먹으러 가자고 하면 네에... 하고 가야합니다.ㅠㅠ",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                    ],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_caution",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "초과근무",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 0,
                sectionTitle: {
                  title: "압박 - 면접/파트너",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },

                  items: {
                    listClass: "alri_keyword_review keyword_warning",
                    totalCount: 3,
                    item:[
                      {
                        dataItemKey: "990024",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "이 많으며 윗사람 눈치를 보고 일해야 한다. 개인적으로 별로다 신규 사업...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                      {
                        dataItemKey: "990025",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "가끔 <em class=\"emph_orange\">야근</em>합니다. 부장님이 술먹으러 가자고 하면 네에... 하고 가야합니다. 꼰...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                      {
                        dataItemKey: "990026",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "가끔 <em class=\"emph_orange\">야근</em>합니다. 부장님이 술먹으러 가자고 하면 네에... 하고 가야합니다.ㅠㅠ",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                    ],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "군대문화",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 0,
                sectionTitle: {
                  title: "무능 - 면접/파트너",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },

                  items: {
                    listClass: "alri_keyword_review keyword_warning",
                    totalCount: 3,
                    item:[
                      {
                        dataItemKey: "997123",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "이 많으며 윗사람 눈치를 보고 일해야 한다. 개인적으로 별로다 신규 사업...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                      {
                        dataItemKey: "997126",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "가끔 <em class=\"emph_orange\">야근</em>합니다. 부장님이 술먹으러 가자고 하면 네에... 하고 가야합니다. 꼰...",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                      {
                        dataItemKey: "997129",
                        type: "modal",
                        social: "[잡플래닛]",
                        text: "가끔 <em class=\"emph_orange\">야근</em>합니다. 부장님이 술먹으러 가자고 하면 네에... 하고 가야합니다.ㅠㅠ",
                        data: "2019.11.19 17:42:46",
                        mainContent: {
                          access: "allow",
                          mainTitle:"잡플래닛 리뷰",
                          dataItemType:"review",
                          info: {
                            total: "<span class=\"um_newstar_c\" style=\"width: 80%;\"></span>",
                            items:[
                              {
                                category:"승진기회 및 가능성",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"복지 및 급여",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"업무와 삶의 균형",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"사내문화",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              },
                              {
                                category:"경영진",
                                comment:"<span class=\"um_newbar_c\" style=\"width: 80%;\"></span>"
                              }
                            ],
                            conclusion: "이 기업을 <em>추천</em> 합니다!"
                          },
                          details:{
                            title: "좋은 복지를 지니고 수평적인 조직문화를 위해 힘쓰는 곳<br />개발에 힘쓰는 곳도 있으나 현상유지 외에 관심이 없는 곳도 있음<br />초봉은 동종 업체들에 비해 낮은편",
                            documentInfo:"최초 작성일 : 2019-01-15 | IT/인터넷 | 전직원 | 경기",
                            textSucess:{
                              title:"장점",
                              text: "복지가 업계에서 제일 좋다고 생각함 수평적인 조직문화를 가지고 있어서 불편한 점이 크게 없음 자율선택근무제를 통해서 자유로운 출퇴근이 가능(하지만 이것도 팀별로 차이가 있음)",
                            },
                            textDanger:{
                              title: "단점",
                              text: "팀마다 다르기 때문에 다 같진 않지만 고인물이 많다 또한 눈에 보이지 않는 정치질로 프로세스가 많아 아래가 피곤한 경우가 있음 간간히 월급 루팡 보임",
                            },
                            textPrimary:{
                              items:[
                                {
                                  title: "경영진에게 바라는 점",
                                  text: "조금 더 도전적이고 혁신적인 사례를 많이 만들었으면 좋겠음 최근 이미지 쇄신에 많이 노력중인데 훨씬 더 노력해야 할 것으로 보임",
                                },
                              ]
                            },
                          }
                        }
                      },
                    ],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
            ]
          },
        },
        {
          dataTab: '경영/운영',
          keywords: {
            sectionTitle: {
              title: "위험도별 키워드",
              description: "",
            },
            keyword:{
              items:[
                {
                  level: "tag_danger",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "성희롱",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "스트레스",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "야근",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "차별",
                },
              ],
            },
            resultComment:{
              typeA:
                "<em class=\"emph_deep_green\">최근 6개월</em> 동안 <em class=\"emph_deep_green\"><a href=\"/alri/category/100/keyword/%EC%84%B1%ED%9D%AC%EB%A1%B1?range=six-month\" style=\"color: rgb(253, 94, 82);\">노조</a> 외 11개 키워드</em>로 <em class=\"emph_deep_green\">25건</em>의 컨텐츠가 감지되었습니다.<br><em class=\"emph_deep_green\">경고성</em> 컨텐츠가 <em class=\"emph_deep_green\">12건</em> 으로 가장 많습니다.",
              typeB:
                "<span><a href=\"/alri/category/100/keyword/%EC%95%95%EB%B0%95?range=six-month\" style=\"color:#7ed321\">압박</a></span>,<span><a href=\"/alri/category/100/keyword/%EC%8A%A4%ED%8A%B8%EB%A0%88%EC%8A%A4?range=six-month\" style=\"color:#F5A623\">스트레스</a></span>,<span><a href=\"/alri/category/100/keyword/%EC%95%BC%EA%B7%BC?range=six-month\" style=\"color:#F5A623\">야근</a></span> 등 키워드의 컨텐츠가 자주 감지되고 있습니다. '조직문화' 영역의 키워드에 대한 관심이 필요합니다.",
            }
          },
          content:{
            sectionTitle: {
              title: "컨텐츠 분석",
              description: "",
            },
            pieGraph:{
              totalCount: 11,
              career: {
                item: [
                  {
                    title: "영업제휴",
                    percentage: "45",
                  },
                  {
                    title: "서비스/고객지원",
                    percentage: "26",
                  },
                  {
                    title: "IT/인터넷",
                    percentage: "17",
                  },
                  {
                    title: "인사/총무",
                    percentage: "13",
                  },
                  {
                    title: "전문직",
                    percentage: "55",
                  },
                  {
                    title: "마케팅/시장조사",
                    percentage: "10",
                  },
                ]
              },
              employmentType:{
                item: [
                  {
                    title: "정규직",
                    percentage: "68",
                  },
                  {
                    title: "계약직",
                    percentage: "74",
                  },
                  {
                    title: "아르바이트",
                    percentage: "34",
                  },
                  {
                    title: "인턴직",
                    percentage: "12",
                  },
                  {
                    title: "프리랜서",
                    percentage: "16",
                  },
                ]
              },
              items: {
                listClass: "",
                totalCount: 0,
                item:[],
              },
              resultComment:"<em class=\"emph_deep_green\">잡플래닛</em> 리스크 컨텐츠는 총 <em class=\"emph_deep_green\">26건</em>이며, <em class=\"emph_deep_green\">영업/제휴 직군/정규직</em>이 각각 가장 많았습니다.",
            },
            barGraph:{
              totalCount: 4,
              resultComment:"<em class=\"emph_deep_green\">SNS/커뮤니티</em> 리스크 컨텐츠는 총 <em class=\"emph_deep_green\">41건</em>이며, <em class=\"emph_deep_green\">트위터와 블로그</em>에서 가장 많이 모니터링 되고 있습니다.",
            }
          },
          keywordDetails:{
            pageTitle:{
              type: "default",
              text: "키워드 상세 분석",
            },
            items: [
              {
                keyword: "성희롱",
                level: "label_danger",
                levelText: "위험",
                totalCountClass:"emph_red",
                totalCount: 0,
                sectionTitle: {
                  title: "성희롱 - 면접/파트너",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "",
                    totalCount: 0,
                    item:[],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_danger",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "스트레스",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 0,
                sectionTitle: {
                  title: "스트레스 - 조직문화",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "",
                    totalCount: 0,
                    item:[],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "야근",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 1,
                sectionTitle: {
                  title: "야근 - 조직문화",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "",
                    totalCount: 0,
                    item:[],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "차별",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 0,
                sectionTitle: {
                  title: "차별 - 조직문화",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "",
                    totalCount: 0,
                    item:[],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
            ]
          },
        },
        {
          dataTab: '불공정',
          keywords: {
            sectionTitle: {
              title: "위험도별 키워드",
              description: "",
            },
            keyword:{
              items:[
                {
                  level: "tag_caution",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "압박",
                },
                {
                  level: "tag_caution",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "무능",
                },
                {
                  level: "tag_caution",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "소모품",
                },
                {
                  level: "tag_danger",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "성희롱",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "스트레스",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "야근",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "차별",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "사내정치",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "초과근무",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "군대문화",
                },
                {
                  level: "tag_warning",
                  linkTo: "/alri/category/100/keyword/자살?range=six-month",
                  text: "횡포",
                },
              ],
            },
            resultComment:{
              typeA:
                "<em class=\"emph_deep_green\">최근 6개월</em> 동안 <em class=\"emph_deep_green\"><a href=\"/alri/category/100/keyword/%EC%84%B1%ED%9D%AC%EB%A1%B1?range=six-month\" style=\"color: rgb(253, 94, 82);\">해지</a> 외 11개 키워드</em>로 <em class=\"emph_deep_green\">25건</em>의 컨텐츠가 감지되었습니다.<br><em class=\"emph_deep_green\">경고성</em> 컨텐츠가 <em class=\"emph_deep_green\">12건</em> 으로 가장 많습니다.",
              typeB:
                "<span><a href=\"/alri/category/100/keyword/%EC%95%95%EB%B0%95?range=six-month\" style=\"color:#7ed321\">압박</a></span>,<span><a href=\"/alri/category/100/keyword/%EC%8A%A4%ED%8A%B8%EB%A0%88%EC%8A%A4?range=six-month\" style=\"color:#F5A623\">스트레스</a></span>,<span><a href=\"/alri/category/100/keyword/%EC%95%BC%EA%B7%BC?range=six-month\" style=\"color:#F5A623\">야근</a></span> 등 키워드의 컨텐츠가 자주 감지되고 있습니다. '조직문화' 영역의 키워드에 대한 관심이 필요합니다.",
            }
          },
          content:{
            sectionTitle: {
              title: "컨텐츠 분석",
              description: "",
            },
            pieGraph:{
              totalCount: 19,
              career: {
                item: [
                  {
                    title: "영업제휴",
                    percentage: "42",
                  },
                  {
                    title: "서비스/고객지원",
                    percentage: "24",
                  },
                  {
                    title: "IT/인터넷",
                    percentage: "14",
                  },
                  {
                    title: "인사/총무",
                    percentage: "10",
                  },
                  {
                    title: "전문직",
                    percentage: "5",
                  },
                  {
                    title: "마케팅/시장조사",
                    percentage: "5",
                  },
                ]
              },
              employmentType:{
                item: [
                  {
                    title: "정규직",
                    percentage: "38",
                  },
                  {
                    title: "계약직",
                    percentage: "24",
                  },
                  {
                    title: "아르바이트",
                    percentage: "14",
                  },
                  {
                    title: "인턴직",
                    percentage: "10",
                  },
                  {
                    title: "프리랜서",
                    percentage: "10",
                  },
                ]
              },
              items: {
                listClass: "",
                totalCount: 0,
                item:[],
              },
              resultComment:"<em class=\"emph_deep_green\">잡플래닛</em> 리스크 컨텐츠는 총 <em class=\"emph_deep_green\">21건</em>이며, <em class=\"emph_deep_green\">영업/제휴 직군/정규직</em>이 각각 가장 많았습니다.",
            },
            barGraph:{
              totalCount: 4,
              resultComment:"<em class=\"emph_deep_green\">SNS/커뮤니티</em> 리스크 컨텐츠는 총 <em class=\"emph_deep_green\">4건</em>이며, <em class=\"emph_deep_green\">트위터와 블로그</em>에서 가장 많이 모니터링 되고 있습니다.",
            }
          },
          keywordDetails:{
            pageTitle:{
              type: "default",
              text: "키워드 상세 분석",
            },
            items: [
              {
                keyword: "압박",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 0,
                sectionTitle: {
                  title: "압박 - 조직문화",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "",
                    totalCount: 0,
                    item:[],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "무능",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 0,
                sectionTitle: {
                  title: "무능 - 조직문화",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "",
                    totalCount: 0,
                    item:[],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "소모품",
                level: "label_caution",
                levelText: "주의",
                totalCountClass:"emph_light_green",
                totalCount: 0,
                sectionTitle: {
                  title: "소모품 - 조직문화",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "",
                    totalCount: 0,
                    item:[],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "성희롱",
                level: "label_danger",
                levelText: "위험",
                totalCountClass:"emph_red",
                totalCount: 0,
                sectionTitle: {
                  title: "성희롱 - 면접/파트너",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "",
                    totalCount: 0,
                    item:[],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_danger",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "스트레스",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: "0",
                sectionTitle: {
                  title: "스트레스 - 조직문화",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "",
                    totalCount: 0,
                    item:[],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "야근",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 1,
                sectionTitle: {
                  title: "야근 - 조직문화",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "",
                    totalCount: 0,
                    item:[],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "차별",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: "0",
                sectionTitle: {
                  title: "차별 - 조직문화",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "",
                    totalCount: 0,
                    item:[],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "사내정치",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 0,
                sectionTitle: {
                  title: "사내정치 - 면접/파트너",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "",
                    totalCount: 0,
                    item:[],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "초과근무",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 0,
                sectionTitle: {
                  title: "압박 - 면접/파트너",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "",
                    totalCount: 0,
                    item:[],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "군대문화",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 0,
                sectionTitle: {
                  title: "무능 - 면접/파트너",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "",
                    totalCount: 0,
                    item:[],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },
              {
                keyword: "횡포",
                level: "label_warning",
                levelText: "경고",
                totalCountClass:"emph_orange",
                totalCount: 0,
                sectionTitle: {
                  title: "횡포 - 면접/파트너",
                  description: "",
                },
                pieGraph:{
                  totalCount: 19,
                  career: {
                    item: [
                      {
                        title: "영업제휴",
                        percentage: "42",
                      },
                      {
                        title: "서비스/고객지원",
                        percentage: "24",
                      },
                      {
                        title: "IT/인터넷",
                        percentage: "14",
                      },
                      {
                        title: "인사/총무",
                        percentage: "10",
                      },
                      {
                        title: "전문직",
                        percentage: "5",
                      },
                      {
                        title: "마케팅/시장조사",
                        percentage: "5",
                      },
                    ]
                  },
                  employmentType:{
                    item: [
                      {
                        title: "정규직",
                        percentage: "38",
                      },
                      {
                        title: "계약직",
                        percentage: "24",
                      },
                      {
                        title: "아르바이트",
                        percentage: "14",
                      },
                      {
                        title: "인턴직",
                        percentage: "10",
                      },
                      {
                        title: "프리랜서",
                        percentage: "10",
                      },
                    ]
                  },
                  items: {
                    listClass: "",
                    totalCount: 0,
                    item:[],
                  },
                },
                barGraph:{
                  totalCount: 2,
                  listClass: "alri_keyword_review keyword_warning",
                  item: [
                    {
                      social: "[블로그]",
                      type: "link",
                      linkTo: "https://blog.naver.com/anizm/221369490399",
                      text: "[다이어트 일기] 평범하게 지나갈 수 있는 하루, 갑작스런 데이트로...",
                      data: "2019.11.19 17:42:46"
                    },
                    {
                      social: "[트위터]",
                      type: "link",
                      linkTo: "http://twitter.com/1624481785/status/1045207159137390592",
                      text: "0 #SM #Fd #펨돔 #멜섭 #유플 #풋워십 #풋잡 #페이스시팅..",
                      data: "2018.11.19 17:42:46"
                    }
                  ]
                }
              },

            ]
          },
        }
      ],
    },
    otherContents:{
      pageTitle:{
        type: "list",
        mainTitle: "못다한이야기",
        active: "못다 한 이야기",
        text: {
          items:[
            {
              linkTo: "#",
              innerText: "못다 한 이야기",
            }
          ]
        },
      },
      sectionTitle: {
        title: "",
        description: "못다한 이야기는 좋은 기업을 만들기 위해 사용자가 작성한 콘텐츠입니다.<br/>내용 검증이 되지 않은 작성자의 주관이 담겨 있는 콘텐츠로 사실과 다를 수 있습니다.<br/>콘텐츠의 2차적 활용 및 공유는 삼가해 주시고, 좋은 기업을 만들 수 있는 데이터로 참고해 주시기 바랍니다.<br/>콘텐츠에 대한 저작사용권 등의 권리는 모두 잡플래닛에 있습니다.<br />캡쳐한 스크린샷을 유포/공유할 경우 모든 책임은 행위자에게 있으며 법적인 제재를 받을 수 있습니다.<br/>",
      },
      activeTab:"total",
      tabMenu:{
        items:[
          {
            dataTab: "total",
            tabId: "",
            text: "전체",
          },
          {
            dataTab: "company",
            tabId: "",
            text: "(주)엘지유플러스",
          },
        ]
      },
      items:[
        {
          dataTab: 'total',
          tabId: "total_contents",
          content: {
            currentCategory:"대기업 / IT/웹/통신 / 작성 경로 - 전체",
          },
          item: [
            {
              dataItemKey:"569965",
              title:"카페** - 중견기업 IT/웹/통신",
              content: "팀장이 팀원들에게 고성으로 화를 내며 회의를 하는 모습을 볼때 여기가 군대인가란 생각이 듭니다. 특정팀에 국한된 문제이지만 구성원 수로 볼때 전직원수의 10%에 해당하는 팀임입니다. 업무문화 개선이 필요합니다.",
              route: "리뷰",
              date: "2019-03-12",
            },
            {
              dataItemKey:"569966",
              title:"라인*** - 중견기업 IT/웹/통신",
              content: "무능한 임원들이 너무 많다. 다른 기업들 처럼 임원 평가도 하고 그 평가에 따라 계약연장여부를 결정하면 좀 더 열심히 자기 일 처럼 하려는 의지가 생기지 않을까. 몇몇 국가에서 잘 안되는 이유를 곰곰히 더 생각해보았으면. 결국엔 다 사람이 하는 일인데 사람이 문제일 수도 있다는 생각을 안하는 것 같다?",
              route: "인터뷰",
              date: "2019-03-01",
            },
            {
              dataItemKey:"569964",
              title:"데이** - 중견기업 IT/웹/통신",
              content: "경영지원팀장이 정말 꼰대중의 꼰대처럼 보였고. 수평적 기업인가 라는 의구심이 들정도로 꼰대적인 마인드가 다분해 보였음. 그리고 상장사에 대한 자격지심이 엄청 다분해 보이고 무시하는 발언등을 서슴없이함.",
              route: "인터뷰",
              date: "2019-03-01",
            },
            {
              dataItemKey:"569967",
              title:"위메* - 중견기업 IT/웹/통신",
              content: "1차면접을 하고 나오면서 합격하더라도 가지 말아야겠다 라고 생각한 이유는 다음과 같습니다 1. 체계가 안잡혀있다는 느낌. 인사체계에서만 그럴 수도 있겠지만, 기본적으로 면접자가 오면 안내해주는 사람의 부재, 채용하는 포지션에 대한 이해도 부족, 애초에 필요하지 않은 포지션을 채용 공고로 냄, 포지션과 무관한 사람들이 면접관으로 참석. 압박면접이라며 사전 양해를 구하는 척 하지만 사실 본인의 원래 성격을 가리기 위함에 불과. 인사도 안하고 건들대며 질문하는 태도. 2. 사무실 분위기가 너무 삭막함(사람들이 숨도 안쉬고 일만 함) 3. 잡플래닛 평점.. 이게 가장 결정적이었음. 이렇게 평점이 낮은 회사 처음 봄. 연봉 정보가 정확한 지는 모르겠지만 어느 정도 맞다고 가정하면 입사했어도 현직장보다 낮은 금액을 받게 된다는 소리. 박봉에 워라밸 안좋고 나와 동일한 포지션이 없는 조직이라 성장할 가능성도 없으니 갈 이유가 없음",
              route: "인터뷰",
              date: "2019-02-22",
            },
            {
              dataItemKey:"569968",
              title:"티* - 중견기업 IT/웹/통신",
              content: "업체 삥 뜻기 구걸 업무 그만. (판관비를 업체에게 떠 넘기기 할당.) 불필요한 취합 업무로 인한 반복 업무 소요 시간만 높아지고 의미 없는 야근진행. 의미 없는 취합 반복 업무로 1월 부터 매주 줄줄이 퇴사중. 각 팀에 T.O가 있음에도 이미 업계 소문은 나서, 경력직 지원도 없음. 사람도 안뽑아줌. 남은 사람들만 죽어라 고생중.",
              route: "리뷰",
              date: "2019-02-19",
            },
          ],
        },
        {
          dataTab: 'company',
          tabId: "compnay_contents",
          content: {
            currentCategory:"",
          },
          item: [],
        }
      ],
      ask:{
        title: "문의하고 싶으신가요?",
        content: "기업 담당자의 이메일이나 아래 이메일을 통해 문의사항을 보내주세요.<br />요청이 접수되면 담당자가 연락을 드립니다.<br />기업담당자 : company@jobplanet.co.kr"
      },
      filter: {
        items: [
          {
            filterTitle:"기업규모",
            dataId: "c1",
            className: "form-group c1"
          },
          {
            filterTitle:"산업군",
            dataId: "c2",
            className: "form-group c2",
          },
          {
            filterTitle:"경로",
            dataId: "c3",
            className: "form-group c3",
          }
        ]
      },
    }
  }
};


export default initialState;
