import React from  'react';
import JplyCheckBox from "../../JplyTemp/components/JplyCheckBox";
import { isEmpty } from "../../Helper/gm";
import getGAevent from "../lib/getGAevent";

const ModalCategoryInfo = ({deleteFirstOrder, modalClose}) => {
  const base_url = process.env.REACT_APP_BACKEND_HOST;
  const datas = [
    {
      title: "콘텐츠 관리",
      img_url: "https://jpassets.jobplanet.co.kr/production/uploads/material/media/5618/banner_contents.png",
      alt: "리뷰/면접/연봉/복지 벙보를 효과적으로 관리해보세요.",
      url: `${base_url}/partners/review_managements`
    },
    {
      title: "채용",
      img_url: "https://jpassets.jobplanet.co.kr/production/uploads/material/media/5784/banner_hire.png",
      alt: "구직자 10명 중 9명이 이용하는 잡플래닛에서 인재를 채용하세요.",
      url: `/partners/job_management`
    },
    {
      title: "홍보",
      img_url: "https://jpassets.jobplanet.co.kr/production/uploads/material/media/5785/banner_marketing.png",
      alt: "우리 회사 소개, 업무 문화 다양한 이야기도 구직자와 함께 나눠요.",
      url: `${base_url}/partners/profile/edit`
    },
    {
      title: "데이터",
      img_url: "https://jpassets.jobplanet.co.kr/production/uploads/material/media/5781/banner_data.png",
      alt: "우리 회사가 얼마나 경쟁력 있는지 객관적인 수치로 확인하세요.",
      url: `${base_url}/partners/dashboard`
    },
  ];

  const gaEventHandler = ({label}) => {
    getGAevent({
      action: "Popup_랜딩팝업",
      label
    });
  };

  const onChecked = (key, value) => {
    if(value){
      localStorage.setItem("PartnersCategoryModalCloseDate", new Date());
    } else {
      localStorage.removeItem("PartnersCategoryModalCloseDate");
    }
  };

  const closeModalHandler = () => {
    modalClose();
    deleteFirstOrder();
  };

  return (
    <div className="jply_popup layer_popup_box layer_popup_box_on">
      <div className="layer_popup_bg"></div>
      <div className={`layer_popup jply_modal_contents_ty modal_category_info`} tabIndex={0}>
        <div className={`jply_modal_v2`}>
          <div className="modal_contents_wrap">
            <div className="modal_header">
              <div className="title">쉽고 간단한 기업관리의 시작, 잡플래닛</div>
            </div>
            <div className="modal_body">
              <div className="modal_sub_text">
                고민은 관리만 늦출 뿐! 무엇이 가장 궁금하세요?
              </div>
              <div className="category_info_box">
                {
                  !isEmpty(datas) &&
                  <ul className="category_info_list">
                    {
                      datas.map(({title, img_url, alt, url}, i) =>
                        <li className="category_info_item" key={i}>
                          <a
                            href={url}
                            onClick={() => {
                              gaEventHandler({
                                label: title
                              });
                            }}>
                            <img src={img_url} alt={alt} title={title} />
                          </a>
                        </li>
                      )
                    }
                  </ul>
                }
              </div>
              <div className="button_group">
                <button
                  className="jply_btn_lg ty_solid block"
                  type="button"
                  onClick={() => {
                    gaEventHandler({
                      label: "기업관리센터 홈으로 가기"
                    });
                    closeModalHandler();
                  }}>
                  기업관리센터 홈으로 가기
                </button>
                <div className="check_box_wrap">
                  <JplyCheckBox
                    value={false}
                    name={"30일동안 보지 않기"}
                    onChecked={onChecked}
                    keyName={"close"}
                  />
                </div>
              </div>
            </div>
          </div>
          <button
            type="button"
            className="btn_close_x_ty1"
            onClick={() => {
              gaEventHandler({
                label: "닫기"
              });
              closeModalHandler();
            }}>
            <span className="jply_icon jp-x" ></span>
            <span className="sr-only">닫기</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalCategoryInfo;
