import React, { Component } from 'react';
import $ from "jquery";
import "jquery-ui/ui/widgets/autocomplete";
import { localStorageGet } from "../sagas/saga_helper";
import { isEmpty } from "../Helper/gm";

class B2bSpaModal extends Component {
  constructor(props) {
    super(props);
    this.state={
      base_url: process.env.REACT_APP_BACKEND_HOST,
      value: {
        id: "",
        name: "",
      },
    }
  }
  componentDidMount() {
    let that = this;
    let base_url = that.state.base_url;
    let autodom = ".company_managing";
    $('#company_name').autocomplete({
      source: function(request, response) {
        $.ajax({
          url : `${base_url}/autocomplete/company_names.json`,
          dataType : 'json',
          type: 'GET',
          data : {
            term : request.term,
            with_industry : 'true',
            exact_name : 'true'
          },
          success: function(data) {
            response(data.nicknames);
          }
        });
      },
      select: function(event, ui) {
        if (event.preventDefault) {
          event.preventDefault();
        } else {
          event.returnValue = false;
        }
        let autoinput_id = ui.item.id;
        let autoinput_value = ui.item.label.split(' | ')[0];

        that.setState({
          value: {
            id: autoinput_id,
            name: autoinput_value
          },
        });

        $("#company_name").val(autoinput_value);
        $("#company_name").focusout();
        $("#company_name").blur();
      },
      selectFirst: true,
      open: function () {
        let widget = $(this).data("ui-autocomplete"),
          menu = widget.menu;
        let $ul = menu.element;
        $(autodom).append($ul);
        $($ul).addClass("jply_ui_autocomplete");
        $ul.css({
          position: "absolute",
          width: "100%",
          zIndex: "100",
          cursor: "default",
          left: "0",
          top: "100%",
          "box-sizing": "border-box"
        });
        $("#company_name").removeClass("ui-corner-all").addClass("ui-corner-top");
      },

      focus: function() {
        /* prevent value inserted on focus */
        return false;
      },
      close: function () {
        $("#company_name").removeClass("ui-corner-top").addClass("ui-corner-all");
      }
    });
  };
  setNewManagingCompany(){
    if(isEmpty(this.state.value.id)) return false;
    $.ajax({
      url : `${this.state.base_url}/api/v3/business_center/info/change_company`,
      dataType : 'json',
      type: 'PUT',
      data : {
        company_id: this.state.value.id,
        access_token: localStorageGet("access_token"),
      },
      success: function(data) {
        if(data.status === "success") {
          window.location.reload();
        }
      }
    });
  }
  render(){
    if (this.props.show === false) return false;
    return (
      <div id="change_company" className="jply_popup layer_popup_box layer_popup_box_on">
        <div className="layer_popup_bg"></div>
        <div className="layer_popup jply_modal_contents_ty" tabIndex={0}>
          <div className="modal-dialog b2b_company_managing">
            <div className="modal-content">
              <div className="modal-header">
                <button type="button"
                        className="close"
                        data-dismiss="modal"
                        onClick={() => {
                          this.props.modalClose();
                        }}>
                  <span aria-hidden="true">×</span>
                  <span className="sr-only" >닫기</span>
                </button>
                <i className="fa fa-laptop modal-icon"></i>
                <h4 className="modal-title">
                  <span className="translation_missing">Change Company</span>
                </h4>
                <small className="font-bold">
                  <span className="translation_missing">Please Choose Company</span>
                </small>
              </div>
              <div className="modal-body">
                <div className="form-group ui-widget company_managing">
                  <label htmlFor="company_name">
                    <span className="translation_missing">Please Search Company</span>
                  </label>
                  <input type="text"
                         className="form-control ui-autocomplete-input ui-corner-all"
                         id="company_name"
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button type="button"
                        className="btn btn-white"
                        onClick={() => {
                          this.props.modalClose();
                        }}>닫기</button>
                <button type="button"
                        onClick={()=>{
                          this.setNewManagingCompany();
                        }}
                        className="btn btn-primary">저장</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default B2bSpaModal;
