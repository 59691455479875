import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import getGAevent from "../lib/getGAevent";
import JplySpinner from "../../JplyTemp/components/JplySpinner";
import useQueryReport from "../hooks/useQueryReport";
import { isEmpty, numberWithCommas } from "../../Helper/gm";

function ModalCompanyReport({ deleteFirstOrder, modalClose }) {
  const BACKEND_HOST = process.env.REACT_APP_BACKEND_HOST;
  const SPA_HOST = process.env.REACT_APP_SPA_HOST;
  const { isFetching, isSuccess, data } = useQueryReport();
  const [number, setNumber] = useState(1);
  const average = arr => {
    const result = arr.reduce(function add(sum, currValue) {
      return sum + currValue;
    }, 0);
    return numberWithCommas(Math.round(result / arr.length));
  };

  Highcharts.setOptions({
    lang: {
      thousandsSep: ",",
    },
  });

  const options = {
    chart: {
      marginBottom: 40,
      marginTop: 60,
      height: 310,
      type: "line",
    },
    credits: false,
    title: {
      text: null,
    },
    xAxis: {
      title: "",
      categories: data && data.data.company_visit && data.data.company_visit.categories,
      crosshair: false,
      labels: {
        y: 28,
        style: {
          fontSize: "13px",
          color: "#85878c",
        },
      },
      tickWidth: 0,
    },
    colors: ["#00c362"],
    yAxis: {
      title: "",
      labels: {
        style: {
          fontSize: "13px",
          color: "#85878c",
        },
        formatter: function () {
          return numberWithCommas(this.value);
        },
      },
      min: 0,
      endOnTick: false,
      tickInterval: 1,
    },
    lang: {
      thousandsSep: ",",
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "13px",
            color: "#323438",
            fontWeight: "normal",
          },
        },
        enableMouseTracking: false,
      },
    },
    legend: {
      symbolRadius: 0,
      enabled: false,
      align: "right",
      verticalAlign: "top",
      y: 0,
      floating: true,
      itemStyle: {
        fontWeight: "normal",
        color: "#85878c",
      },
    },
    series: data && data.data.company_visit && data.data.company_visit.series,
  };

  const nextNumber = number => {
    setNumber(number);
  };

  const closeModalHandler = ({ label }) => {
    modalClose();
    deleteFirstOrder();
    getGAevent({
      action: "Popup_랜딩팝업",
      label,
    });
  };

  const modalRender = () => {
    let component = "";
    switch (number) {
      case 1: {
        component = (
          <div className="modal_contents_wrap">
            <div className="modal_header">
              <div className="title">
                <span className="modal_company_name">{data.data.name}</span>
                <span className="modal_graph__middle">&nbsp;기업페이지에는</span>
                <br />
                <span className="color-green">생각보다 많은사람</span>이 방문합니다.
              </div>
              <span className="modal-overlay">
                6개월 평균 {data.data.company_visit ? average(data.data.company_visit.series[0].data) : 0}건
              </span>
            </div>
            <div className="modal_body">
              <div className="modal_sub_text">실제 {data.data.name} 최근 기업 페이지 조회수</div>
              {data.data.company_visit ? (
                <HighchartsReact className="modal_chart" highcharts={Highcharts} options={options} />
              ) : (
                <div className="modal-empty-graph">
                  <img
                    src="https://jpassets.jobplanet.co.kr/production/uploads/material/media/9255/img_nograph.svg"
                    className="modal-empty-graph__img"
                    alt="데이터가 충분하지 않습니다."
                  />
                  <p className="modal-empty-graph__txt">데이터가 충분하지 않습니다.</p>
                </div>
              )}
              <div className="modal-caption">
                <i className="modal-caption__icon">Q</i> 기업 페이지에 방문하는 사람들의 목적은 무엇일까요?
              </div>
              <div className="button_group">
                <div className="jply_button_group">
                  <button
                    type="button"
                    onClick={() => {
                      nextNumber(2);
                    }}
                    className="jply_btn_lg ty_solid"
                  >
                    다음
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      }
      case 2: {
        component = (
          <div className="modal_contents_wrap">
            <div className="modal_header">
              <div className="title">
                우리 기업 페이지에 방문하는 사람들은
                <br />
                아래와 같은 목적으로 <span className="color-green">직접 검색</span>해서 방문합니다.
              </div>
            </div>
            <div className="modal_body type-margin">
              <div className="modal_graph__img">
                <img
                  src="https://jpassets.jobplanet.co.kr/production/uploads/material/media/9215/img_modal2.svg"
                  alt="입사 지원 의사가 있어요! - 더 자세한 정보가 필요해요! - 기업에 흥미가 있어요!"
                />
              </div>
              <div className="modal-caption">
                <i className="modal-caption__icon">Q</i> 입사 지원까지 유도하는 효과적인 방법이 궁금하신가요??
              </div>
              <div className="button_group">
                <div className="jply_button_group">
                  <button
                    type="button"
                    onClick={() => {
                      nextNumber(3);
                    }}
                    className="jply_btn_lg ty_solid"
                  >
                    다음
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
        break;
      }
      case 3: {
        component = (
          <>
            <div className="modal_contents_wrap">
              <div className="modal_header">
                <div className="title">
                  기업 페이지 방문자를 공고 지원까지 유도하는
                  <br />
                  <span className="color-green">가장 효과적인 방법</span>
                </div>
              </div>
              <div className="modal_body type-margin">
                <div className="modal_graph__img">
                  <img
                    src="https://jpassets.jobplanet.co.kr/production/uploads/material/media/11071/img_relay_mbs.svg"
                    alt="기업 멤버십"
                  />
                </div>
                <div className="modal-caption">
                  <i className="modal-caption__icon">Q</i>다른 기업은 어떻게 활용 중인지 궁금하신가요?
                </div>
                <div className="button_group">
                  <div className="jply_button_group">
                    <a
                      href={`${BACKEND_HOST}/partners/business_consulting?apply_on=b2b_main_relaysignup&redirect_url=${SPA_HOST}/partners/reputation_management`}
                      className="jply_btn_lg ty_solid"
                      rel="noreferrer"
                    >
                      문의하기
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn_close_x_ty1"
              onClick={() => {
                closeModalHandler({
                  label: "닫기",
                });
              }}
            >
              <span className="jply_icon jp-x" />
              <span className="sr-only">닫기</span>
            </button>
          </>
        );
        break;
      }
      default:
        component = "";
        break;
    }
    return component;
  };

  if (isFetching) return <JplySpinner />;
  if (!data || isEmpty(data)) return null;
  if (isSuccess)
    return (
      <div className="jply_popup layer_popup_box layer_popup_box_on">
        <div className="layer_popup_bg" />
        <div className="layer_popup jply_modal_contents_ty modal_category_info modal_graph">
          <div className="jply_modal_v2">{modalRender()}</div>
        </div>
      </div>
    );
}

export default ModalCompanyReport;
