export default class jpAmplitude {
  constructor(id) {
    this.init(id);
  }

  init(userId) {
    if (userId) {
      this.setUserId("JPL_" + userId);
    }
  }

  brazeJoin(event, data){
    if(typeof braze === 'undefined') return false;
    if(braze&&braze.initialize()){
      braze.logCustomEvent(event, data);
    }
  }

  setUserId(value) {
    if(typeof amplitude === 'undefined') return false;
    amplitude&&amplitude.setUserId(value);
  }

  logout() {
    this.setUserId(null);
  }

  sendEvent(event, data) {
    if(typeof amplitude === 'undefined') return false;
    amplitude&&amplitude.track(event, data);
    this.brazeJoin(event, data);
  }

  eventSend(event, data) {
    if(typeof amplitude === 'undefined') return false;
    amplitude&&amplitude.track(event, data);
    this.brazeJoin(event, data);
  }
}
