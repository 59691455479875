import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty } from "../../Helper/gm";
import B2bNav from "../../commons/B2bNavBar";

const PageContainer = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "LOGIN_CHECK",
      action: "PARTNERS_INIT",
      name: "jply_info",
      option: {
        is_auth_check: false,
        test_base_url: isEmpty(props.base_url) ? "" : props.base_url,
        url: "/api/v3/business_center/info/info"
      }
    })
    
  }, [dispatch]);
  
  return (
    <div id="wrapper">
      <div id="B2bGnb">
        <B2bNav target="gnb"/>
      </div>
      <div className="main-layout">
        {props.children}
      </div>
      <div id="B2bBottom">
        <B2bNav target="bottom"/>
      </div>
    </div>
  );
};

export default PageContainer;
