import React from 'react';
import api from '../Helper/axiosGet';
import JplySpinner from '../JplyTemp/components/JplySpinner';
import getGAevent from "../partners/lib/getGAevent";

class G_B2bGnbNotification extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      data: null,
      new: false,
    };
    this.host = process.env.REACT_APP_BACKEND_HOST;
  }
  componentDidMount() {
    this.handleAxiosCall(`${this.host}/partners/notifications.json?noti_for=noti_bar&per=5`)
  };
  
  gaEventHandler = ({label}) => {
    getGAevent({
      action: "Notification",
      label
    });
  };
  
  handleAxiosCall = (url) => {
    api({
      url,
    }).then((res) => {
      const noti = res.data.data.notifications;
      const jply_storage = localStorage.JplyNotification;
      this.setState({
        data: noti,
      });
      if (noti.length > 0 && ((!jply_storage) || (jply_storage < noti[0].id))) {
        const new_id = noti[0].id;
        localStorage.setItem('JplyNotification', new_id);
        this.props.myNotiAction(true);
      }
      if (noti.length) {
        this.setState({
          new: true,
        });
      }
    }).catch((err) => {
      console.log('통신 에러 입니다.', err);
    });
  };
  createListItem = () => {
    if (this.state.data && this.state.data.length > 0) {
      return (<ul className="list">
        {this.state.data.map((item, index) => <li key={index}>
          <a
            href={`${this.host}${item.url}`}
            onClick={() => {
              this.gaEventHandler({
                label: item.message
              })
            }}>
            <div className="cont">
              <div className="alarm_logo" />
              <p className="tit">{item.message}</p>
              <p className="txt">{item.format_created_at}</p>
            </div>
          </a>
        </li>)}
      </ul>);
    }
    return <div className="nodata">알림이 없습니다.</div>;
  }
  render() {
    return (
      <div
        className={`alarm_area b2b_jply ${
          this.props.my_noti_list === true ? 'show_list' : ''
        }`}
        onMouseEnter={() => { this.props.myNotiAction(true); }}
        onMouseLeave={() => { this.props.myNotiAction(false); }}
      >
        <button
          className={`btn_alarm ${this.state.new ? 'new' : ''}`}
          type="button"
          onFocus={() => {
            this.props.myNotiAction(true);
          }}
        >
          <i className="jp-bell" />
          <span>알림</span>
        </button>
        <div className="b2b_alarm_list">
          {this.state.data ? (
            <div className="inner">
              {this.createListItem()}
              <div className="view_all">
                <a
                  href={`${this.host}/partners/notifications`}
                  className="jply_btn_sm ty_default"
                  onBlur={() => { this.props.myNotiAction(false); }}
                  onClick={() => {
                    this.gaEventHandler({
                      label: "전체보기"
                    })
                  }}
                >전체보기</a>
                <button
                  className="jply_btn_sm ty_default"
                  type="button"
                  onClick={() => {
                    this.props.myNotiAction(false);
                    this.gaEventHandler({
                      label: "닫기"
                    })
                  }}
                >닫기</button>
              </div>
            </div>
          ) : <JplySpinner id={'notification_spinner'} />}
        </div>
      </div>
    );
  }
}
export default G_B2bGnbNotification;
