import axios from "axios";
import { localStorageGet } from "../../sagas/saga_helper";

export default function fetchDog(option) {
  return axios({
    timeout: 6000,
    baseURL: process.env.REACT_APP_BACKEND_HOST,
    method: "get",
    url: option.url,
    params: Object.assign({}, option.params, {access_token: localStorageGet('access_token')}),
    headers: {
      access_token: localStorageGet('access_token'),
      Accept: 'application/json'
    },
    withCredentials: true
  });
}
