
/*
* 2023. 07
* 기업관리센터 datalayer들을 하나의 파일에서 관리하기 위해 만든 파일입니다.
*
* 1. 함수 naming 규칙 : dL_event명  (이벤트 명은 jira 설계에 있는 Event Name 입니다, 이벤트명은 camelCase 로 표기합니다.)
* 2. setDataPush 함수에 값을 전달하여 dataLayer Push 합니다.
*
* */


/* 채용공고 등록 버튼을 클릭시 데이터 */
const dL_ClickNewJobPostingInPartner = ({
    path = "", section_title = "", button_title = "", company_id = 0, company_name = ""
}) => {
  const properties = {
    company_id: company_id,
    company_name: company_name,
    page_path: path,
    section_title: section_title,
    button_title: button_title,
  };

  setDataPush("click_new_jobposting_in_partner", properties);
}


/* 전체 공고 관리 - 복사 버튼을 클릭했을때 데이터 */
const dL_CopyJobPosting = ({
  company_id = 0,
  company_name = "",
  job_posting_id = 0
}) => {
  const properties = {
    company_id: company_id,
    company_name: company_name,
    job_posting_id: job_posting_id,
  };

  setDataPush("copy_jobposting", properties);
}

const setDataPush = (eventName, properties) => {
  window.dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: eventName,
    ...properties
  });
};


const dL_clickFitMatchingInPartner = ({
	                                      companyId = 0,
	                                      companyName = "",
	                                      sectionTitle = "",
	                                      buttonTitle = ""
}) => {
	const pagePath = `${window.location.pathname}${window.location.search}`
	setDataPush("click_fit_candidates_in_partner", {
		page_path: pagePath,
		company_id: companyId,
		company_name: companyName,
		section_title: sectionTitle,
		button_title: buttonTitle
	});
}

const dL_sendFitMessages = ({
	                            companyId = 0,
	                            companyName = "",
	                            location = "",
	                            locationTitle = "",
	                            jobPostingId = 0,
	                            resumeId = 0,
	                            applicantUserid = 0,
	                            profileLevel= 0,
	                            yearsOfExperience = 0,
	                            latestCompanyId = 0,
	                            latestCompanyName = 0,
	                            universityInResume = "",
	                            majorInUniversity = ""
                            }) => {
	const pagePath = `${window.location.pathname}`;
	setDataPush("send_fit_messages", {
		page_path: pagePath,
		company_id: companyId,
		company_name: companyName,
		location: location,
		location_title: locationTitle,
		job_posting_id: jobPostingId,
		resume_id: resumeId,
		applicant_userid: applicantUserid,
		profile_level: profileLevel,
		years_of_experience: yearsOfExperience,
		latest_company_id: latestCompanyId,
		latest_company_name: latestCompanyName,
		education: universityInResume,
		major: majorInUniversity,
	});
}

const dL_clickResumeInPartnerFitMatching = ({
	                                            companyId = 0,
	                                            companyName = "",
	                                            location = "",
	                                            locationTitle = "",
	                                            resumeId = 0,
	                                            profileLevel = 0,
	                                            yearsOfExperience = 0,
	                                            latestCompanyId= 0,
	                                            latestCompanyName = "",
	                                            universityInResume= "",
	                                            majorInUniversity = "",
	                                            isBulkOpen = false,
	                                            openType = ""
                                            }) => {
	const pagePath = `${window.location.pathname}`;
	setDataPush("click_resume_in_partner_fit_candidates", {
			page_path: pagePath,
			company_id: companyId,
			company_name: companyName,
			location: location,
			location_title: locationTitle,
			resume_id: resumeId,
			profile_level: profileLevel,
			years_of_experience: yearsOfExperience,
			latest_company_id: latestCompanyId,
			latest_company_name: latestCompanyName,
			education: universityInResume,
			major: majorInUniversity,
	});
}

/* 채용공고 등록 페이지 진입 시 - view_partner_job_postings */
const dL_ViewPartnerJobPostings = ({
	company_id = 0, company_name = "", type = "", company_product_name = ""
}) => {
	const properties = {
		company_id: company_id,
		company_name: company_name,
		type: type,
		company_product_name: company_product_name,
	};

	setDataPush("view_partner_job_postings", properties);
}

/* 채용공고 등록 및 수정 페이지 내 채용상품문의 클릭 시 - click_recruitment_product_requiry */
const dL_ClickRecruitmentProductRequiry = ({
	company_id = 0, company_name = "", job_posting_id = 0, location_title = "", company_product_name = ""
}) => {
	const properties = {
		company_id: company_id,
		company_name: company_name,
		job_posting_id: job_posting_id,
		location_title: location_title,
		company_product_name: company_product_name,
	};

	setDataPush("click_recruitment_product_requiry", properties);
}

export {
	dL_clickFitMatchingInPartner,
	dL_sendFitMessages,
	dL_clickResumeInPartnerFitMatching,
	dL_ClickNewJobPostingInPartner,
	dL_CopyJobPosting,
	dL_ViewPartnerJobPostings,
	dL_ClickRecruitmentProductRequiry
}
