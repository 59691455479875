import { call, put } from "redux-saga/effects";
import apiGet from "../Helper/axiosGet";
import { isEmpty } from "../Helper/gm";
import { spinnerLoading, localStorageSet } from "./saga_helper";

function* setUserInfo(userdata) {
  const {
    user_id,
    customer_name,
    email,
    is_confirm_email,
    last_visit_at,
    is_student,
    is_business_account,
    business_account_permission,
    company_name,
    company_rating,
    privilege_master_code,
    approved_at,
    industry_rating
  } = userdata;
  const jpchannelio_vaul = {
    "pluginKey": process.env.REACT_APP_CHANNELIO_KEY,
    "hideChannelButtonOnBoot": true,
    "memberId": user_id,
    "profile":{
      "name": customer_name,
      "email": email,
      "is_confirm_email": is_confirm_email,
      "last_visit_at": last_visit_at,
      "is_student": is_student,
      "is_business_account": is_business_account,
      "business_account_permission": business_account_permission,
    }
  }
  yield put({
    type: "SET_USER",
    addBy: {
      company_name,
      company_rating,
      privilege_master_code,
      approved_at,
      industry_rating,
    }
  });
  ChannelIO && ChannelIO('boot', jpchannelio_vaul);
}

export default function* loginCheckSage(action) {
  try {
    /*api콜*/
    const test_set_url = process.env.REACT_APP_BACKEND_HOST;
    yield call(spinnerLoading, "on");
    const response = yield call(apiGet, { url: "/api/v3/business_center/info/info" , test_base_url: test_set_url});
    yield call(spinnerLoading, "off");

    if (response.data.code === 500) {
      yield call(spinnerLoading, "end");
      console.log("500 Error");
    }
    const { data } = response.data;
    if (isEmpty(data.user)) {
      /* 로그인 하지 않았을 때 */
      localStorageSet("jobplanet_spa", "login_no");
      window.location.href = `${test_set_url}/users/sign_in?redirect_url=${window.location.href}`;
    } else {
      const operatorUrl = "/partners/operator_managements"
      if(data.user  && !data.user.is_configured && !window.location.href.includes(operatorUrl) ){
        alert(`모든 운영자에게 역할을 부여해야 서비스 이용이 가능합니다.\n운영자 관리 페이지로 이동합니다.`);
        window.location.href = operatorUrl;
        return;
      }
      if(data.user.is_permission){
        /* 기업 계정 승인이 완료됐을 때 */
        if(action.option.is_auth_check){
          /* 알리 권한 체크가 필요한 경우 */
          if (data.user.is_alri_auth) {
            localStorageSet("jobplanet_spa", "login_ok");
          } else {
            alert("접근 권한이 없습니다.");
            localStorageSet("jobplanet_spa", "login_out");
            window.location.href = `${test_set_url}/partners/business_consulting?apply_on=f_gnb_alri&redirect_url=${process.env.REACT_APP_SPA_HOST}/partners/reputation_management`;
          }
        }else{
          /* 알리 권한 체크하지 않아도 될 때 */
          localStorageSet("jobplanet_spa", "login_ok");
          if (data.user.access_token){
            localStorageSet("access_token", data.user.access_token);
          }
          if (data.user.refresh_token){
            localStorageSet("refresh_token", data.user.refresh_token);
          }
          if(!isEmpty(data.user.agree_tos_recruitment_url)){
            /* 채용서비스 약관 동의를 하지 않았을 경우 */
            window.location.href = data.user.agree_tos_recruitment_url;
          }
        }
        /* store에 저장할 기업 정보 */
        yield call(setUserInfo, data.user);
      }else{
        /* 기업 계정 승인이 대기 중일 때 */
        alert("접근 권한이 없습니다.");
        window.location.href = data.user.permission_denied_landing_url;
      }
    }
    yield put({ type: action.action, addBy: response.data, name: action.name });
  } catch (e) {
    yield call(spinnerLoading, "end");
    const errorCode = e.message?.match(/\d+/)[0];
    const test_set_url = process.env.REACT_APP_BACKEND_HOST;

    if(errorCode === "401"){
      alert("로그인 페이지로 이동합니다.");
      localStorageSet("jobplanet_login", "login_check_error");
      window.location.href = `${test_set_url}/users/sign_in?redirect_url=${window.location.href}`;
      return;
    }
    if(errorCode === "403") {
      localStorageSet("jobplanet_login", "login_check_error");
      window.location.href = `/partners/access_error`;
      return;
    }
    if(errorCode === "404") {
      alert("기업계정이 아닙니다. 기업계정 권한을 받으신 후 다시 이용해주세요.");
      localStorageSet("jobplanet_login", "login_check_error");
      window.location.href = `${test_set_url}/job`;
      return;
    }
    if(e.message.indexOf("503") > 0){
      window.location.href = `/error/503`;
    }
    if(e.message.indexOf("500") > 0){
      window.location.href = `/error/500`;
    }
    localStorageSet("jobplanet_spa", "error");
    alert("죄송합니다.\n 서버 및 통신 장애로 잠시 후 접속 바랍니다.");
    window.location.href = process.env.REACT_APP_BACKEND_HOST;
  }
}
