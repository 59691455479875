import React, { useState, useEffect, useCallback } from "react";
import qs from "qs";
import useModal from "../hooks/useModal";
import B2bSpaModal from "../../commons/B2bSpaModal";
import ModalUtilizationRate from "./ModalUtilizationRate";
import ModalCategoryInfo from "./ModalCategoryInfo";
import ModalWelcomeTasks from "./ModalWelcomeTasks";
import useCompanyUser from "../hooks/useCompanyUser";
import useQueryModal from "../hooks/useQueryModal";
import { isEmpty } from "../../Helper/gm";
import ModalCompanyReport from "./ModalCompanyReport";
import ModalClaimReview from "./ModalClaimReview";

function ModalContainer() {
  const { isSuccess, data } = useQueryModal();
  const [openableModalOrder, setOpenOrder] = useState([]);
  const { modal, onClickModalOpen, onClickModalClose } = useModal();
  const { user } = useCompanyUser();

  const havePassed30days = datetime => {
    if (!datetime) return false;

    const today = new Date();
    const lastDay = new Date(datetime);
    const timeGap = (today - lastDay) / (60 * 60 * 1000);

    return timeGap > 24 * 30;
  };

  const getDisplayComponyReportState = (data, user) => {
    const {
      business_account_signup_after,
      business_account_signup_first_access,
      branding_service_payment,
      branding_service_termination,
    } = data;
    const { privilege_master_code } = user;
    /* 기업관리센터 최초첩근여부 */
    if (business_account_signup_first_access) {
      return true;
    }
    /* 프리미엄회원이 아닌경우 */
    if (privilege_master_code !== "premium") {
      /* 이전에브랜딩 서비스 결제 && 브랜딩 서비스 해지 후 15일이 지난 경우 */
      if (branding_service_payment && branding_service_termination) {
        return true;
      }
      /* 기업 회원 가입 후 60일이 지난 경우 */
      if (business_account_signup_after) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (isEmpty(user)) return;

    const query = qs.parse(window.location.pathname, {
      ignoreQueryPrefix: true,
    });
    if (Object.keys(query)[0] !== "/partners/reputation_management") {
      return;
    }

    const modalOrderArr = [];

    if (isSuccess) {
      const displayComponyReport = getDisplayComponyReportState(data.data, user);
      if (displayComponyReport) {
        modalOrderArr.push({
          modal_name: "COMPANY_REPORT",
        });
      }
    }

    if (
      !localStorage.getItem("PartnersCategoryModalCloseDate") ||
      havePassed30days(localStorage.getItem("PartnersCategoryModalCloseDate"))
    ) {
      modalOrderArr.push({
        modal_name: "CATEGORY_INFO_MODAL",
      });
    }
    if (!localStorage.getItem("PartnersWelcomeModalCloseDate") && !havePassed30days(user.approved_at)) {
      modalOrderArr.push({
        modal_name: "WELCOME_TASKS_MODAL",
      });
    }
    if (modalOrderArr.length > 0) {
      setOpenOrder(modalOrderArr);
    }
  }, [user]);

  useEffect(() => {
    if (openableModalOrder.length > 0) {
      onClickModalOpen({
        name: openableModalOrder[0].modal_name,
      });
    }
  }, [openableModalOrder]);

  const deleteFirstOrder = useCallback(() => {
    const newOrderState = [...openableModalOrder];

    newOrderState.splice(0, 1);
    setOpenOrder(newOrderState);
  }, [openableModalOrder]);

  const selectModal = modalName => {
    switch (modalName) {
      case "UTILIZATION_RATE_MODAL":
        return <ModalUtilizationRate modalClose={onClickModalClose} />;
      case "CATEGORY_INFO_MODAL":
        return <ModalCategoryInfo modalClose={onClickModalClose} deleteFirstOrder={deleteFirstOrder} />;
      case "WELCOME_TASKS_MODAL":
        return <ModalWelcomeTasks modalClose={onClickModalClose} deleteFirstOrder={deleteFirstOrder} />;
      case "CHANGE_COMPANY_MODAL":
        return <B2bSpaModal show={modal.show} modalClose={onClickModalClose} />;
      case "CLAIM_REVIEW_MODAL":
        return <ModalClaimReview reviewId={modal.reviewId}/>
      case "COMPANY_REPORT":
        return (
          <ModalCompanyReport show={modal.show} modalClose={onClickModalClose} deleteFirstOrder={deleteFirstOrder} />
        );
      default:
        return null;
    }
  };

  if (!modal.show || !modal.name) return null;
  return <>{selectModal(modal.name)}</>;
}

export default ModalContainer;
