import axios from "axios";

export default function fetchDog(option) {
  const {url, method, params, headers} = option;

  return axios({
      headers: Object.assign({
          "X-CSRF-Token": window.ajaxtoken
      }, headers),
      method,
      url,
      data: params
  });
}
