import React, { Suspense } from "react";
import {Redirect, Switch} from "react-router-dom";
import { PublicRoute } from "react-router-with-props";
import "../assets/scss/certify_otp.scss";

import JplySpinner from "../commons/JplySpinner";
import { CertifyOtpPage } from './pages';

const test_base_url = process.env.REACT_APP_BACKEND_HOST;

const CertifyOtp = () => {
  return (
    <Suspense fallback={<JplySpinner />}>
      <Switch>
        <PublicRoute
          exact
          path="/certify_otp"
          component={CertifyOtpPage}
          base_url={test_base_url}
        />
        <Redirect to="/error"/>
      </Switch>
    </Suspense>
  );
};

export default CertifyOtp;