export default class jpbranchio {
  constructor() {
    this.key = process.env.REACT_APP_BRANCH;
    this.init();
  }
  init() {
    if(typeof branch === 'undefined') return false;
    branch&&branch.init(this.key);
  }
  track(target){
    if(typeof branch === 'undefined') return false;
    branch&&branch.track("pageview");
  }
  setIdentity(id){
    if(typeof branch === 'undefined') return false;
    branch&&branch.setIdentity(id, function (err, data) {
      if(process.env.REACT_APP_BACKEND_HOST !== "https://www.jobplanet.co.kr") {
        console.log(err, data);
      }
    });
  }
  logout() {
    if(typeof branch === 'undefined') return false;
    branch&&branch.logout(function (err, data) {
      if(process.env.REACT_APP_BACKEND_HOST !== "https://www.jobplanet.co.kr") {
        console.log(err, data);
      }
    });
  }
}
